import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SaveAltIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete";
import UnassignIcon from "@material-ui/icons/SettingsBackupRestore";
import CloseIcon from "@material-ui/icons/Close";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ViewListIcon from "@material-ui/icons/ViewList";
import LayersIcon from "@material-ui/icons/Layers";
import EditIcon from "@material-ui/icons/Edit";

// import DialogInfoBox from "../../../components/DialogInfoBox/DialogInfoBox";
import DialogDataInfo from "../../../components/DialogInfoBox/sections/DialogDataInfo";
import DialogEditInfo from "../../../components/DialogInfoBox/sections/DialogEditInfo";
import DialogMap from "../../../components/DialgoMap/DialogMap.js";

const ModalDialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

class DialogInfo extends Component {
  state = { info: {}, edit: false, errors: "", valueTab: 0 };

  UNSAFE_componentWillMount() {
    // console.log("mount...", this.props);
    this.setState({ edit: this.props.edit, valueTab: this.props.edit ? 2 : 0 });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log("receive...", this.props);
    this.setState({ edit: nextProps.edit });
  }

  handleChangeTab = (event, valueTab) => {
    // console.log(event, valueTab);
    this.setState({ valueTab });
  };

  render() {
    const { actions, open, close, info, allData, user } = this.props;
    const { edit, valueTab } = this.state;
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={close}
        aria-labelledby="simple-dialog-title"
      >
        <ModalDialogTitle id="simple-dialog-title" onClose={this.handleClose}>
          {info.event}
        </ModalDialogTitle>
        <DialogTitle style={{ padding: 0 }}>
          <AppBar position="static" color="default">
            <Tabs
              value={valueTab}
              onChange={this.handleChangeTab}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Data" icon={<ViewListIcon />} />
              <Tab label="Map" icon={<LayersIcon />} />
              <Tab label="Edit" icon={<EditIcon />} />
            </Tabs>
          </AppBar>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          {valueTab === 0 && <DialogDataInfo info={info} allData={allData} user={user} actions={actions}/>}
          {valueTab === 2 && (
            <DialogEditInfo
              actions={actions}
              info={info}
              allData={allData}
              user={user}
            />
          )}
          {valueTab === 1 && (
            <div>
              {info.hasOwnProperty("areas") && (
                <div>
                  {info.areas.features.length > 0 ? (
                    <DialogMap info={info} actions={actions} user={user} />
                  ) : (
                    <div style={{ padding: 20 }}>
                      <Typography color="error" variant="h5" gutterBottom>
                        features not available
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </DialogContent>
        {valueTab < 2 && (
          <DialogActions>
            {this.state.errors && (
              <div style={{ padding: 10 }}>
                <Typography color="error" variant="subtitle2" gutterBottom>
                  {JSON.stringify(this.state.errors)}
                </Typography>
              </div>
            )}
            {!edit ? (
              <React.Fragment>
                {info.status === "UN" ? (
                  <React.Fragment>
                    <IconButton
                      onClick={this.approveInfo}
                      aria-label="Add"
                      color="secondary"
                      disabled={info.gdacs_severity === null ? true : false}
                    >
                      <Tooltip title="Approve" placement="top">
                        <SaveAltIcon />
                      </Tooltip>
                    </IconButton>

                    <IconButton
                      onClick={this.deleteInfo}
                      aria-label="Delete"
                      style={{
                        color: red[500]
                      }}
                    >
                      <Tooltip title="Delete" placement="top">
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                  </React.Fragment>
                ) : (
                  <Tooltip title="Undo action" placement="top">
                    <IconButton
                      onClick={this.unassignInfo}
                      aria-label="Undo"
                      style={{ color: orange[500] }}
                    >
                      <UnassignIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </React.Fragment>
            ) : (
              <div />
            )}
            <Tooltip title="Close" placement="top">
              <IconButton onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </DialogActions>
        )}
      </Dialog>
    );
  }

  handleClose = _ => {
    // console.log("close.........");
    this.setState({ info: {}, errors: null });
    this.props.close();
  };

  deleteInfo = _ => {
    this.props.actions.deleteInfo(this.props.info, this.props.identifier);
    this.props.close();
  };

  approveInfo = _ => {
    this.props.actions.approveInfo(this.props.info, this.props.identifier);
    this.props.close();
  };

  unassignInfo = _ => {
    this.props.actions.unassignInfo(this.props.info, this.props.identifier);
    this.props.close();
  };
}

DialogInfo.propTypes = {
  actions: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  allData: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default DialogInfo;
