import React from "react";
// import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import green from "@material-ui/core/colors/green";
import deepOrange from "@material-ui/core/colors/deepOrange";
import CssBaseline from "@material-ui/core/CssBaseline";
import "typeface-lato";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createTheme ({
  typography: { 
    useNextVariants: true,
    fontFamily: ["Lato", "Arial", "sans-serif"].join(",")
  },
  palette: {
    primary: {
      light: "#faa73f",
      main: "#f39C30",
      dark: deepOrange[700],
      contrastText: "#fff"
    },
    secondary: {
      light: green[300],
      main: green[500],
      dark: green[700]
    }
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
