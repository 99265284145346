import React, { Component } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import CompareParameters from "./CompareParameters";
import SystemParameterTable from "../SystemParameterTable";
import { STATUS_COLOR, updateHistory } from "../../lib/utils";

import HazardClassifications from "./HazardClassifications";

import ReactJson from 'react-json-view'

import moment from "moment";

const MOMENT_FORMAT = "MMMM Do YYYY";

const styles = {
  col1: {
    fontWeight: "bold",
  },
};

class InfoTable extends Component {
  state = {
    value: "",
    infoUpdated: [],
    history: {}
  };

  UNSAFE_componentWillMount() {
    if (this.props.info.gdacs_severity !== null) {
      this.setState({ value: `${this.props.info.gdacs_severity.id}` });
    }
    const infoUpdated = (this.props.infoToReview && this.props.infoToReview.filter(
      (i) => i.info.pk === this.props.info.identifier
    )) || [];
    if (infoUpdated.length > 0) this.setState({ infoUpdated });
    if (this.props.info.history !== null) {
      this.setState({ history: this.props.info.history })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.info.gdacs_severity !== null) {
      this.setState({ value: `${nextProps.info.gdacs_severity.id}` });
    }
  }

  handleChangeRadioGDACS = (event) => {
    this.setState({ value: event.target.value });
    this.props.actions.gdacs(
      this.props.info,
      this.props.identifier,
      event.target.value
    );
  };

  render() {
    const { info, allData, classes, actions } = this.props;
    const bkgColor = {
      backgroundColor: STATUS_COLOR[info.status],
      marginBottom: 40,
    };
    const fieldGdacs = allData.info[5].results;
    const fieldHazard = allData.info[10] && allData.info[10].map(info => ({ ...info, code: info.name, value: info.name }));
    return (
      <Grid item xs={12} style={bkgColor}>
        <Paper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.col1} style={{ width: "10%" }}>
                  gdacs alert
                </TableCell>
                <TableCell style={{ width: "90%" }}>
                  <div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="GDACS"
                        name="gdacs"
                        value={this.state.value}
                        onChange={this.handleChangeRadioGDACS}
                        row
                      >
                        {fieldGdacs.map((f, idx) => (
                          <FormControlLabel
                            key={`f-gdacs-${idx}`}
                            value={`${f.id}`}
                            control={<Radio />}
                            label={
                              <Tooltip title={f.legend} placement="top">
                                <Typography>{f.name}</Typography>
                              </Tooltip>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </TableCell>
              </TableRow>
              {info.hasOwnProperty("hazard_classifications") &&
                <TableRow>
                  <TableCell
                    className={classes.col1}
                    style={{ verticalAlign: "top" }}
                  >
                    hazard classifications
                </TableCell>
                  <TableCell>
                    <HazardClassifications
                      hazards={fieldHazard}
                      info={info}
                      actions={actions}
                    />
                  </TableCell>
                </TableRow>
              }
              <TableRow>
                <TableCell className={classes.col1}>id</TableCell>
                <TableCell>{info.identifier}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.col1}>date</TableCell>
                <TableCell>{`onset: ${moment(info.onset).format(
                  MOMENT_FORMAT
                )}; expires: ${moment(info.expires).format(
                  MOMENT_FORMAT
                )}; effective: ${moment(info.effective).format(
                  MOMENT_FORMAT
                )}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.col1}>event</TableCell>
                <TableCell>{info.event}</TableCell>
              </TableRow>
              {info.headline && (
                <TableRow>
                  <TableCell className={classes.col1}>headline</TableCell>
                  <TableCell>{info.headline}</TableCell>
                </TableRow>
              )}
              {info.description !== null && (
                <TableRow>
                  <TableCell className={classes.col1}>description</TableCell>
                  <TableCell>
                    <div
                      dangerouslySetInnerHTML={{ __html: info.description }}
                    />
                  </TableCell>
                </TableRow>
              )}
              {info.hasOwnProperty("resources") && (
                <TableRow>
                  <TableCell className={classes.col1}>resources</TableCell>
                  <TableCell>
                    {info.resources.map((r, idx) => (
                      <div key={`resources-${idx}`}>
                        <a
                          href={r.uri}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {r.uri}
                        </a>
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell className={classes.col1}>history</TableCell>
                <TableCell>{Object.keys(this.state.history).length > 0 && <ReactJson src={this.state.history} collapsed />}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {this.state.infoUpdated.length > 0 ? (
            <React.Fragment>
              <SystemParameterTable info={info} handleHistory={this.handleHistory} />
              <CompareParameters
                info={info}
                updated={this.state.infoUpdated}
                actions={actions}
                allData={allData}
              />
            </React.Fragment>
          ) : (
              <SystemParameterTable info={info} handleHistory={this.handleHistory} />
            )}
        </Paper>
      </Grid>
    );
  }

  handleHistory = p => {
    const h = updateHistory(this.state.history, {"parameters": p}, "deleted")
    this.setState({history: h})
    this.props.actions.removeParamtoInfo(this.props.info, p)
    this.props.actions.hystoryInfo(this.props.info, h)
  }

}

InfoTable.propTypes = {
  classes: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoTable);
