import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Hidden,
  Typography,
  Toolbar
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LanguageIcon from "@material-ui/icons/Language";
import MoreIcon from "@material-ui/icons/MoreVert";

const styles = theme => ({
  grow: {
    flexGrow: 1,
    cursor: "pointer"
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  menuBtn: {
    marginRight: 5,
    backgroundColor: "#273B4B",
    color: "#fff"
  },
  changelog: {
    marginRight: 10
  }
});

const OPTIONS = [
  { label: "List view" },
  { label: "Map view" },
  { label: "Search" },
  { label: "Info" },
  { label: "Alert" },
  { label: `v${process.env.REACT_APP_VERSION}` }
];

class Header extends React.PureComponent {
  state = {
    anchorEl: null,
    anchorElMob: null
  };
  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleMenuMob = event => {
    this.setState({ anchorElMob: event.currentTarget });
  };
  handleCloseMob = () => {
    this.setState({ anchorElMob: null });
  };

  render() {
    const { classes, actions, user } = this.props;
    const { anchorEl, anchorElMob } = this.state;
    const open = Boolean(anchorEl);
    const openMob = Boolean(anchorElMob);
    return (
      <AppBar position="static">
        <Toolbar>
          <LanguageIcon
            style={{ marginRight: 5 }}
            onClick={() => actions.menuTab(0)}
          />
          <Typography
            variant="h6"
            color="inherit"
            className={classes.grow}
            onClick={() => actions.menuTab(0)}
          >
            <Hidden smDown>{process.env.REACT_APP_TITLE}</Hidden>
          </Typography>

          <div className={classes.sectionDesktop}>
            {OPTIONS.map((item, idx) => (
              <Button
                key={`btn-${idx}`}
                className={classes.menuBtn}
                variant="contained"
                onClick={() => actions.menuTab(idx)}
              >
                {item.label}
              </Button>
            ))}
            <div>
              <IconButton
                aria-owns={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
                <AccountCircleIcon style={{ marginLeft: 5 }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.handleLogout}>Logout {user.username}</MenuItem>
              </Menu>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="Menu"
              aria-owns="mob-menu"
              aria-haspopup="true"
              color="inherit"
              onClick={this.handleMenuMob}
            >
              <MoreIcon />
            </IconButton>
            <Menu
              id="mob-menu"
              anchorEl={anchorElMob}
              open={openMob}
              onClose={this.handleCloseMob}
            >
              {OPTIONS.map((item, idx) => (
                <MenuItem
                  key={`mnu-${idx}`}
                  onClick={() => actions.menuTab(idx)}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  }

  handleLogout = _ => {
    this.props.actions.logout();
  };
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
