import React from "react";
import PropTypes from "prop-types";

import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
// import FolderIcon from "@material-ui/icons/CheckBoxOutlineBlank";
// import green from "@material-ui/core/colors/green";

import ListComponent from "./ListComponent.js";
import Countries from "./Countries";
import StateHandler from "./StateHandlers.js";

const Continent = ({ item, open, click, actions, allData, infoToReview }) => {
  // const listIcon = <FolderIcon style={{ color: green[500] }} />;
  const classes = {};
  return (
    <React.Fragment>
      <ListComponent
        classes={classes}
        click={click}
        textAvatar={`${actions.getNumActivity("continent", item[0])}`}
        // listIcon={listIcon}
        listText={`${item[0]}`}
        typoText="h5"
        open={open}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Countries
          mapCountries={item[1]}
          actions={actions}
          allData={allData}
          infoToReview={infoToReview}
        />
      </Collapse>
    </React.Fragment>
  );
};

Continent.propTypes = {
  item: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

const WithStateHandler = StateHandler(Continent);

const Continents = ({ alertsTree, actions, allData, infoToReview }) => {
  const continents = Object.entries(alertsTree).sort();
  return (
    <List component="nav">
      {continents.map((c, idx) => (
        <WithStateHandler
          key={`continent-${idx}`}
          item={c}
          actions={actions}
          allData={allData}
          infoToReview={infoToReview}
        />
      ))}
    </List>
  );
};

Continents.propTypes = {
  alertsTree: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired,
  infoToReview: PropTypes.array.isRequired
};

export default Continents;
