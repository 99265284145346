import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import red from "@material-ui/core/colors/red";

function FeatureBox({ feature, actionsArea, idx }) {
  let description = feature.feature.properties.description || "no";
  if (description.length > 30) {
    description = description.substring(0, 30) + "...";
  }
  return (
    <div>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <div onClick={() => actionsArea.delete(idx)}>
                  <Tooltip title="delete">
                    <DeleteIcon style={{ color: red[500] }} />
                  </Tooltip>
                </div>
              </td>
              <td>{description}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default function FeaturesBox({ features, actionsArea }) {
  // console.log(features);
  return (
    <div>
      {features.map((f, idx) => (
        <FeatureBox
          key={`f-${idx}`}
          feature={f}
          actionsArea={actionsArea}
          idx={idx}
        />
      ))}
    </div>
  );
}
