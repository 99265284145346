import React, { Component } from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
// import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import InfoIcon from "@material-ui/icons/Info";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import blue from "@material-ui/core/colors/blue";
// import moment from "moment";

import { STATUS_COLOR } from "../../../lib/utils";

class Layers extends Component {
  state = { open: true };

  render() {
    const { classes } = this.props;
    const jsonInfos = { info: this.props.jsonInfos };
    return (
      <List component="div">
        {jsonInfos.info.map((i, idx) => (
          <ListItem
            key={idx}
            className={classes.nested}
            button
            style={{ backgroundColor: STATUS_COLOR[i.status] }}
          >
            <ListItemText
              onClick={() => this.props.actions.viewInfo(i, i.identifier)}
              primary={
                <Typography variant="body2" gutterBottom>
                  {i.event}
                </Typography>
              }
              secondary={
                i.hasOwnProperty("regions")
                  ? i.regions.map(r => r.name).join(", ")
                  : ""
              }
            />
            <IconButton
              onClick={() => this.props.actions.viewInfo(i, i.identifier)}
            >
              <InfoIcon className={classes.info} style={{ color: blue[500] }} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => this.props.actions.addInfoLayer(i)}
              disabled={
                i.hasOwnProperty("areas")
                  ? i.areas.features.length > 0
                    ? false
                    : true
                  : true
              }
            >
              <MyLocationIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    );
  }
}

Layers.propTypes = {
  classes: PropTypes.object.isRequired,
  jsonInfos: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired
};

export default Layers;
