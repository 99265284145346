import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import DateRangeIcon from "@material-ui/icons/DateRange";
import RefreshIcon from "@material-ui/icons/Refresh";

import { DatePicker } from "@material-ui/pickers";

import { IconButton, InputAdornment } from "@material-ui/core";

import { FILTER_DATE } from "../lib/utils";
// import moment from "moment";

const LIST_LABEL = "FLOOD REPORTS";

const STATUS = {
  0: { code: "UN", lbl: "Unassigned" },
  1: { code: "AP", lbl: "Approved" },
  2: { code: "DI", lbl: "Deleted" }
};

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center"
  }
});

class GroupedCheck extends Component {
  state = {
    checkedA: false,
    checkedB: false,
    checkedC: false,
    selectedDateFrom: null, //FILTER_DATE.from, // moment().subtract(2, "week"), // new Date(),
    selectedDate: null //FILTER_DATE.to, //moment().add(1, "day") // new Date()
  };

  UNSAFE_componentWillMount() {
    Object.keys(STATUS).forEach(key => {
      if (this.props.groupedParams.includes(STATUS[key].code)) {
        switch (STATUS[key].code) {
          case "UN":
            this.setState({ checkedA: true });
            break;
          case "AP":
            this.setState({ checkedB: true });
            break;
          case "DI":
            this.setState({ checkedC: true });
            break;
          default:
            console.log(STATUS[key].code);
        }
      }
    });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
    this.chechBoxdate({ ...this.state, [name]: event.target.checked });
  };

  chechBoxdate = p => {
    if (p.checkedB || p.checkedC) {
      this.setState({
        selectedDateFrom: FILTER_DATE.from,
        selectedDate: FILTER_DATE.to
      });
    }
    if (p.checkedA && !p.checkedB && !p.checkedC) {
      this.setState({ selectedDateFrom: null, selectedDate: null });
    }
  };

  handleDateChange = date => {
    const d = date || "";
    this.setState({ selectedDate: d });
  };

  handleDateChangeFrom = date => {
    const d = date || "";
    this.setState({ selectedDateFrom: d });
  };

  render() {
    const { classes, actions, pageTitle, alertsTree } = this.props;
    return (
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <Typography variant="h5">{pageTitle}</Typography>
            <Typography variant="subtitle1">
              {LIST_LABEL}{" "}
              {Object.keys(alertsTree).length > 0 && (
                <span>({actions.getInfoTot(alertsTree)})</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: "left" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checkedA}
                  onChange={this.handleChange("checkedA")}
                  value="checkedA"
                />
              }
              label="Unassigned"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checkedB}
                  onChange={this.handleChange("checkedB")}
                  value="checkedB"
                />
              }
              label="Approved"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.checkedC}
                  onChange={this.handleChange("checkedC")}
                  value="checkedC"
                />
              }
              label="Deleted"
            />
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: "left" }}>
            <DatePicker
              autoOk
              clearable
              variant="outlined"
              style={{ margin: 3.5, width: 175 }}
              label="from"
              value={this.state.selectedDateFrom}
              onChange={this.handleDateChangeFrom}
              animateYearScrolling={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <DateRangeIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <DatePicker
              autoOk
              clearable
              variant="outlined"
              style={{ margin: 3.5, width: 175 }}
              label="to"
              value={this.state.selectedDate}
              onChange={this.handleDateChange}
              animateYearScrolling={false}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <DateRangeIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
            <Button
              onClick={this.setCheckGroup}
              color="primary"
              variant="outlined"
              disabled={
                this.state.checkedA
                  ? false
                  : this.state.checkedB
                  ? false
                  : this.state.checkedC
                  ? false
                  : true
              }
            >
              <RefreshIcon />
              reload
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  setCheckGroup = _ => {
    if (
      this.state.selectedDateFrom === null &&
      this.state.selectedDate === null
    ) {
      this.props.actions.setGroupParams(`?status=${STATUS[0].code}`);
      return;
    }
    let q = [this.state.checkedA, this.state.checkedB, this.state.checkedC]
      .map((s, idx) => {
        if (s) {
          return `${STATUS[idx].code}`;
        }
        return null;
      })
      .filter(s => s !== null)
      .join(",");
    if (this.state.checkedA || this.state.checkedB || this.state.checkedC) {
      if (this.state.selectedDateFrom !== null) {
        q +=
          "&modified__gte=" +
          this.state.selectedDateFrom.toISOString().substr(0, 19);
      }
      if (this.state.selectedDate !== null) {
        q +=
          "&modified__lte=" +
          this.state.selectedDate.toISOString().substr(0, 19);
      }
    }
    if (q.length > 1) this.props.actions.setGroupParams(`?status=${q}`);
  };
}

GroupedCheck.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  groupedParams: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  alertsTree: PropTypes.object.isRequired
};

export default withStyles(styles)(GroupedCheck);
