import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import BaseMap from "../../components/BaseMap/BaseMap";
import NoData from "../NoData";
import GroupedCheck from "../GroupedCheck";

const PAGE_TITLE = "MAP VIEW";

class AlertsMap extends Component {
  render() {
    const { actions, alertsTree, groupedParams, isLoadingGrouped } = this.props;
    if (typeof alertsTree !== "object") return <NoData />;
    if (alertsTree.hasOwnProperty("error")) return <NoData />;
    return (
      <Grid container>
        <Grid item xs={12}>
          <GroupedCheck
            actions={actions}
            groupedParams={groupedParams}
            pageTitle={PAGE_TITLE}
            alertsTree={alertsTree}
          />
        </Grid>
        {isLoadingGrouped ? (
          <Grid item xs={12}>
            <div style={{ padding: 10 }}>
              <Typography variant="h6">WAIT... RETRIEVING DATA</Typography>
            </div>
          </Grid>
        ) : (
          <Grid item xs={12} md={12}>
            {Object.keys(alertsTree).length > 0 ? (
              <BaseMap
                identifier="alerts-map"
                alertsTree={alertsTree}
                actions={actions}
              />
            ) : (
              <NoData />
            )}
          </Grid>
        )}
      </Grid>
    );
  }
}

AlertsMap.propTypes = {
  actions: PropTypes.object.isRequired,
  alertsTree: PropTypes.object.isRequired,
  groupedParams: PropTypes.string.isRequired,
  isLoadingGrouped: PropTypes.bool.isRequired
};

export default AlertsMap;
