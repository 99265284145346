import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

// import SelectAlerts from "../../../components/AddNew/SelectAlerts";
import AddRegion from "../../../components/AddNew/SelectRegions";
import AddInfo from "../../../components/AddNew/AddInfo";
import AddParams from "../../../components/AddNew/AddParams";
import AddStepper from "../../../components/AddNew/AddStepper";
import AddEnd from "../../../components/AddNew/AddEnd";
// import AutocompleteRegion from "../../../components/AddNew/AutocompleteRegion";

import { addNewInfo } from "../../../api/api.js";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
});

const steps = ["Select Region", "Create Info", "Add Params"];

class AddNewInfo extends Component {
  state = { activeStep: 0, newMessage: {}, errors: null };

  render() {
    const { classes, user, allData } = this.props;
    const { activeStep, newMessage, errors } = this.state;
    return (
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12} style={{ padding: 10 }}>
              <Typography variant="h5" gutterBottom>
                Add new info
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root} elevation={1}>
                <AddStepper activeStep={activeStep} steps={steps} />
                {activeStep < 1 && (
                  <AddRegion
                    nextStep={this.handleSelect}
                    data={newMessage}
                    user={user}
                  />
                )}
                {activeStep === 1 && (
                  <AddInfo
                    nextStep={this.handleSelect}
                    data={newMessage}
                    allData={allData}
                  />
                )}
                {activeStep === 2 && (
                  <AddParams
                    nextStep={this.handleSelect}
                    data={newMessage}
                    allData={allData}
                  />
                )}
                {activeStep === 3 && (
                  <AddEnd nextStep={this.handleSelect} errors={errors} />
                )}
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  handleSelect = (activeStep, data) => {
    if (activeStep < 3) {
      this.setState({
        activeStep,
        newMessage: { ...data }
      });
    } else {
      const info = { ...data.info[0] };
      if (data.parameters.length > 0) info.parameters = data.parameters;
      info.areas = data.areas;
      info.source_id = 4;
      info.audience = "GDACS";
      info.contact = "Efas dissemination center";
      info.response_types_ids = [1];
      this.addInfo(activeStep, info);
    }
  };

  addInfo = async (activeStep, info) => {
    delete info["categories"];
    delete info["urgency"];
    delete info["severity"];
    delete info["certainty"];
    const response = await addNewInfo(this.props.user, info);
    if (response.hasOwnProperty("identifier")) {
      this.setState({
        activeStep,
        newMessage: {},
        errors: null
      });
      this.props.actions.addInfo(response.alert_id);
    } else {
      this.setState({
        activeStep,
        newMessage: {},
        errors: response
      });
    }
  };
}

AddNewInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

export default withStyles(styles)(AddNewInfo);
