import { withStateHandlers } from "recompose";

const StateHandler = (BaseComponent) => withStateHandlers(
  {
    open: false
  },
  {
    click: ({ open }) => () => ({ open: !open })
  }
)(BaseComponent);

export default StateHandler