import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

// import TextField from "@material-ui/core/TextField";
// import Select from "@material-ui/core/Select";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary  from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
// import blue from "@material-ui/core/colors/blue";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  paper: {
    marginTop: 10,
    padding: theme.spacing(4),
    width: "100%"
  },
  chip: {
    margin: theme.spacing(1)
  }
});

class EditParam extends Component {
  state = { description: "", quantitative: "" };

  UNSAFE_componentWillMount() {
    this.setState({ description: this.props.param.description });
  }

  render() {
    const p = this.props.param;
    return (
      <Paper style={{ marginBottom: 10 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head" style={{ width: "10%" }}>
                name
              </TableCell>
              <TableCell style={{ width: "90%" }}>
                {p.system_parameter.value_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" style={{ width: "10%" }}>
                description
              </TableCell>
              <TableCell style={{ width: "90%" }}>
                <div>
                  <TextField
                    fullWidth
                    value={this.state.description}
                    onChange={this.handleChangeDesc}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="2"
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" style={{ width: "10%" }}>
                value
              </TableCell>
              <TableCell style={{ width: "90%" }}>
                <div>
                  <TextField
                    type="number"
                    value={this.state.quantitative}
                    onChange={this.handleChangeQta}
                    margin="normal"
                    helperText="quantitative"
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" style={{ width: "10%" }} />
              <TableCell style={{ width: "90%" }}>
                <Button
                  onClick={() => this.props.remove(this.props.idx)}
                  variant="contained"
                  color="primary"
                >
                  remove
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  handleChangeDesc = evt => {
    this.setState({ description: evt.target.value });
    this.props.update(this.props.idx, "description", evt.target.value);
  };

  handleChangeQta = evt => {
    this.setState({ quantitative: evt.target.value });
    this.props.update(this.props.idx, "quantitative", evt.target.value);
  };
}

class AddParams extends Component {
  state = { params: [] };

  render() {
    const { classes, nextStep, data, allData } = this.props;
    return (
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <ExpansionPanel>
          <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1" gutterBottom>
              Add Params
            </Typography>
          </ExpansionPanelSummary >
          <AccordionDetails>
            <Paper className={classes.paper} elevation={1}>
              <div style={{ maringBottom: 10 }}>
                {this.state.params.map((p, idx) => (
                  <EditParam
                    key={`p-${idx}`}
                    param={p}
                    idx={idx}
                    remove={this.handleRemove}
                    update={this.handleUpdate}
                  />
                ))}
              </div>
              {allData.info[7].map((i, idx) => (
                <Chip
                  key={`all-${idx}`}
                  className={classes.chip}
                  label={i.value_name}
                  onClick={() => this.handleChipClick(i)}
                />
              ))}
            </Paper>
          </AccordionDetails>
        </ExpansionPanel>
        <Paper className={classes.paper} elevation={1}>
          <Button
            onClick={() => nextStep(1, { ...data })}
            variant="outlined"
            color="primary"
          >
            prev
          </Button>
          <Button
            onClick={() =>
              nextStep(3, { ...data, parameters: this.state.params })
            }
            variant="outlined"
            color="secondary"
            style={{ marginLeft: 5 }}
          >
            save
          </Button>
        </Paper>
      </div>
    );
  }

  handleUpdate = (idx, field, value) => {
    const params = this.state.params.map((p, i) => {
      if (i === idx) {
        p[field] = value;
        return p;
      } else {
        return p;
      }
    });
    this.setState(params);
  };

  handleRemove = idx => {
    let params = [...this.state.params];
    params.splice(idx, 1);
    this.setState({ params });
  };

  handleChipClick = item => {
    const p = {
      system_parameter_id: item.id,
      description: "...",
      quantitative: null,
      unit_id: 2,
      extra: {},
      areas: [],
      system_parameter: null
    };
    p["system_parameter"] = item;
    const params = [...this.state.params, p];
    this.setState({ params });
  };
}

AddParams.propTypes = {
  classes: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default withStyles(styles)(AddParams);
