import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import AddNewAlert from "./AddNewAlert.js";
import AlertsContainer from "../../../components/Alerts/AlertsContainer";
import DSSAlertsContainer from "../../../components/Alerts/DSSAlertsContainer";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function Alerts({ user, actions, allData }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="List alerts" />
        <Tab label="Add new" />
        <Tab label="DSS alerts" />
      </Tabs>
      {value === 0 && (
        <AlertsContainer user={user} actions={actions} allData={allData} />
      )}
      {value === 1 && (
        <AddNewAlert user={user} actions={actions} allData={allData} />
      )}
      {value === 2 && (
        <DSSAlertsContainer user={user} actions={actions} allData={allData} />
      )}

    </div>
  );
}
