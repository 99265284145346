import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Continents from "./sections/Continents";
import NoData from "../NoData";

class InfosTree extends Component {
  render() {
    const { alertsTree, actions, allData, infoToReview } = this.props;
    if (typeof alertsTree !== "object") return <NoData />;
    if (alertsTree.hasOwnProperty("error")) return <NoData />;
    return (
      <Grid container>
        <Grid item xs={12} md={12}>
          <Divider style={{ marginTop: 8 }} />
          {Object.keys(alertsTree).length > 0 ? (
            <Continents
              alertsTree={alertsTree}
              actions={actions}
              allData={allData}
              infoToReview={infoToReview}
            />
          ) : (
            <NoData />
          )}
        </Grid>
      </Grid>
    );
  }
}

InfosTree.propTypes = {
  alertsTree: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired,
  infoToReview: PropTypes.array.isRequired
};

export default InfosTree;
