import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

// import { DatePicker } from "material-ui-pickers";
import { DatePicker } from "@material-ui/pickers";

import { IconButton, InputAdornment } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";

import moment from "moment";

import { addNewAlert, searchInfos } from "../../../api/api.js";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: 20,
    marginRight: 20,
    width: 550
  },
  dateField: {
    marginBottom: 30,
    marginTop: 30,
    marginLeft: 20,
    marginRight: 20
  },
  selectField: {
    marginTop: 30,
    marginLeft: 20,
    marginRight: 20
  }
});

class AddNewAlert extends Component {
  state = {
    description: "",
    title: "",
    onset: "",
    expires: "",
    country: "",
    event: "",
    infos: { results: [] },
    infosID: []
  };

  UNSAFE_componentWillMount() {
    // const current = new Date().toJSON().substr(0, 10);
    this.setState({
      onset: moment().subtract(2, "week"),
      expires: moment().add(1, "day")
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleDateChangeFrom = date => {
    const d = date || "";
    this.setState({ onset: d });
  };

  handleDateChangeTo = date => {
    const d = date || "";
    this.setState({ expires: d });
  };

  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ padding: 10 }}>
              <Typography variant="h5" gutterBottom>
                Add new alert
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {/* <button onClick={this.handleTest}>test</button> */}
              <div>
                <TextField
                  fullWidth
                  id="title"
                  label="Title"
                  // className={classes.textField}
                  variant="outlined"
                  multiline
                  rows="2"
                  value={this.state.title}
                  onChange={this.handleChange("title")}
                  margin="normal"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <TextField
                  fullWidth
                  id="description"
                  label="Description"
                  // className={classes.textField}
                  variant="outlined"
                  multiline
                  rows="2"
                  value={this.state.description}
                  onChange={this.handleChange("description")}
                  margin="normal"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root} elevation={1}>
                <Grid container style={{ marginTop: 20 }}>
                  <Grid item xs={12} className={classes.container}>
                    <DatePicker
                      autoOk
                      clearable
                      className={classes.dateField}
                      variant="outlined"
                      style={{ width: 175 }}
                      label="from"
                      helperText="onset time"
                      value={this.state.onset}
                      onChange={this.handleDateChangeFrom}
                      animateYearScrolling={false}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <DateRangeIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    <DatePicker
                      autoOk
                      clearable
                      className={classes.dateField}
                      variant="outlined"
                      style={{ width: 175 }}
                      label="to"
                      helperText="expires time"
                      value={this.state.expires}
                      onChange={this.handleDateChangeTo}
                      animateYearScrolling={false}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <DateRangeIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />

                    <TextField
                      id="country"
                      label="Country"
                      className={classes.dateField}
                      value={this.state.country}
                      // helperText={countryHelper}
                      onChange={this.handleChange("country")}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    <TextField
                      id="event"
                      label="Event"
                      className={classes.dateField}
                      value={this.state.event}
                      helperText="contains"
                      onChange={this.handleChange("event")}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    <Button
                      onClick={this.handleSearchInfos}
                      variant="outlined"
                      color="primary"
                      style={{ marginLeft: 10 }}
                      disabled={this.state.event.length > 2 ? false : true}
                    >
                      Search info
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid item xs={4}>
              <div>
                {this.state.infos.results.length > 0 && (
                  <div>
                    <Typography variant="subtitle1" gutterBottom>
                      Found infos [{this.state.infos.count}]
                    </Typography>
                  </div>
                )}
                {this.state.infos.results.map((i, idx) => (
                  <div
                    key={`infos-${idx}-${i.identifier}`}
                    onClick={() => this.addInfoID(i)}
                  >
                    {i.event}
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                {this.state.infosID.length > 0 && (
                  <div>
                    <Typography variant="subtitle1" gutterBottom>
                      info added to alert [{this.state.infosID.length}]
                    </Typography>
                  </div>
                )}
                {this.state.infosID.map(i => (
                  <div
                    key={`id-${i.identifier}`}
                    onClick={() => this.removeInfoID(i)}
                  >
                    [added] {i.event}
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <Button
                  onClick={this.handleAddAlert}
                  variant="outlined"
                  color="secondary"
                  disabled={this.state.infosID.length > 50 ? false : true}
                >
                  add alerts
                </Button>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  handleSearchInfos = async _ => {
    // this.setState({ loading: true, count: -1, response: { results: [] } });
    let queryString = `?onset__gte=${this.state.onset
      .toISOString()
      .substr(0, 19)}&expires__lte=${this.state.expires
      .toISOString()
      .substr(0, 19)}`; // "?infos__modified__gte=2019-01-17T12:00:00";
    if (this.state.country.length > 2)
      queryString += `&regions__country__name__istartswith=${
        this.state.country
      }`;
    if (this.state.event.length > 2)
      queryString += `&event__icontains=${this.state.event}`;

    const response = await searchInfos(this.props.user, queryString);
    if (response.hasOwnProperty("results")) {
      this.setState({ infos: response });
    } else {
      this.setState({ infos: { results: [] } });
    }
  };

  addInfoID = info => {
    const infosID = [
      ...this.state.infosID.filter(i => i.identifier !== info.identifier),
      info
    ];
    this.setState({ infosID });
  };

  removeInfoID = info => {
    const infosID = [
      ...this.state.infosID.filter(i => i.identifier !== info.identifier)
    ];
    this.setState({ infosID });
  };

  handleAddAlert = async _ => {
    const infosID = this.state.infosID.map(i => i.identifier);
    if (this.state.title.length > 2) {
      const alert = {
        status_id: 1,
        msg_type_id: 1,
        source_id: 1,
        scope_id: 1,
        infos_id: infosID,
        title: this.state.title,
        description: this.state.description
      };
      const response = await addNewAlert(this.props.user, alert);
      console.log(response);
    }
  };
}

AddNewAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

export default withStyles(styles)(AddNewAlert);
