import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import SystemParameterTable from "../../SystemParameterTable";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";

import moment from "moment";

import { searchCountries, searchRegions, putInfoCountries, putInfoRegions } from "../../../api/api.js";

const MOMENT_FORMAT = "MMMM Do YYYY, h:mm a";


class InfoAddRegion extends React.Component {
  render() {
    const { region, regionsID, actions, countryID } = this.props;
    const isEnabled = regionsID.includes(region.id)
    return (
      <div>
        {!isEnabled && (
          <div>{region.country.id===countryID && <Chip onClick={() => actions.updateRegionID(region.id)} label={region.name} />}</div>
        )}
      </div>
    )
  }
}

class InfoCountryRegion extends React.Component {

  handleDeleteRegion = regionID => {
    this.props.actions.deleteRegionID(regionID)
  }

  render() {
    const { region } = this.props;
    return (
      <Grid container>
        <Grid item xs={10}>
          <div>{region.name}</div>
        </Grid>
        <Grid item xs={2}>
        <Tooltip title="delete region">
            <DeleteForeverIcon
              onClick={() => this.handleDeleteRegion(region.id)}
              style={{ color: red[700] }}
            />
          </Tooltip>
        </Grid>
      </Grid>
    )
  }
}


class InfoCountry extends React.Component {
  state = {
    isAdd:true
  }

  handleGetRegionList = countryID => {
    this.props.actions.getRegionList(countryID)
    this.setState({isAdd:!this.state.isAdd})
  }

  handleClearRegionList = _ => {
    this.setState({isAdd:!this.state.isAdd})
    this.props.actions.clearRegionList()
  }

  handleDeleteCountry = countryID => {
    this.props.actions.deleteCountryID(countryID)
  }

  render() {
    const { country, regions, regionList, regionsID, actions } = this.props;
    const cregions = regions.filter(r => r.country.id===country.id)
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Grid container>
                <Grid item xs={1}>
                  <Tooltip title="delete country">
                    <DeleteForeverIcon
                      onClick={() => this.handleDeleteCountry(country.id)}
                      style={{ color: blue[700] }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  {country.name}
                </Grid>
                <Grid item xs={6}>
                  <div>{cregions.map((region) => 
                    <InfoCountryRegion key={`cregion-list-${region.id}`}
                      region={region}
                      actions={actions} />)}
                  </div>
                  <div>{this.state.isAdd 
                    ? (<Button onClick={()=>this.handleGetRegionList(country.id)}
                        variant="outlined"
                        color="secondary">add region</Button>) 
                    : (<Button onClick={this.handleClearRegionList}
                        variant="outlined"
                        color="secondary">hide</Button>)
                    }
                  </div>
                  {regionList.length>0 && <div><hr></hr></div>}
                  <div>{regionList.map((region) => 
                    <InfoAddRegion key={`region-list-${region.id}`}
                      region={region} 
                      regionsID={regionsID}
                      actions={actions}
                      countryID={country.id} />)}
                  </div>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
};

InfoCountry.propTypes = {
  country: PropTypes.object.isRequired,
  regions: PropTypes.array.isRequired,
  regionList: PropTypes.array.isRequired,
  regionsID: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired
};


class DialogDataInfo extends React.Component {
  state = {
    countriesID:[],
    regionsID:[],
    regionList: [],
    countriesList: [],
    isAddCountry:true
  }

  UNSAFE_componentWillMount() {
    const countriesID = this.props.info.countries.map(r => r.id)
    const regionsID = this.props.info.regions.map(r => r.id)
    this.setState({countriesID,regionsID})
  }

  handleDeleteCountryID = (countryID) => {
    const found = this.props.info.regions.filter(region => region.country.id===countryID)
    if (found.length>0) {
      alert('remove regions before delete this country')
      return
    }
    const countriesID = this.state.countriesID.filter(r => r !== countryID)
    this.setState({countriesID})
    this._updateCountries(countriesID)
  }

  handleUpdateCountryID = (countryID) => {
    let set = new Set(this.state.countriesID)
    set.add(countryID)
    const countriesID = [...set]
    this.setState({countriesID,countriesList:[],isAddCountry:!this.state.isAddCountry})
    this._updateCountries(countriesID)
  }

  handleDeleteRegionID = (regionID) => {
    const regionsID = this.state.regionsID.filter(r => r !== regionID)
    this.setState({regionsID})
    this._updateRegions(regionsID)
  }

  handleUpdateRegionID = (regionID) => {
    let set = new Set(this.state.regionsID)
    set.add(regionID)
    const regionsID = [...set]
    this.setState({regionsID})
    this._updateRegions(regionsID)
  }

  handleRegionCountry = (countryID) => {
    this._getRegions(countryID);
  }

  handleClearRegionList = _ => {
    this.setState({regionList:[]})
  }

  handleCountriesList = _ => {
    this._getCountries()
    this.setState({isAddCountry:!this.state.isAddCountry})
  }

  handleClearCountriesList = _ => {
    this.setState({isAddCountry:!this.state.isAddCountry, countriesList:[]})
  }

  _getCountries = async _ => {
    const response = await searchCountries(this.props.user, '')
    if (response.hasOwnProperty("results")) {
      const countriesList = response.results
      this.setState({ countriesList});
    }    
  }

  _getRegions = async id => {
    const response = await searchRegions(this.props.user, `?country__id=${id}`);
    if (response.hasOwnProperty("results")) {
      const regionList = response.results
      this.setState({ regionList});
    }
  };

  _updateCountries = async countriesID => {
    const data = {"countries":countriesID}
    const response = await putInfoCountries(this.props.user,this.props.info.identifier,data)
    if (response.hasOwnProperty('result')) {
      if (response.result==='OK') {
        this.props.actions.reloadInfo(this.props.info.identifier);
      }
    }
  }

  _updateRegions = async regionsID => {
    const data = {"regions":regionsID}
    const response = await putInfoRegions(this.props.user,this.props.info.identifier,data)
    if (response.hasOwnProperty('result')) {
      if (response.result==='OK') {
        this.props.actions.reloadInfo(this.props.info.identifier);
      }
    }
  }

  render() {
    const { info, allData } = this.props;
    const dateFields = ["onset", "expires", "effective"];
    const legends = allData.info[8];
    const actions = {
      getRegionList: this.handleRegionCountry,
      clearRegionList: this.handleClearRegionList,
      updateRegionID: this.handleUpdateRegionID,
      deleteRegionID: this.handleDeleteRegionID,
      deleteCountryID: this.handleDeleteCountryID
    }
    return (
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>gdacs severity</TableCell>
              <TableCell>
                {info.gdacs_severity === null ? (
                  "not set yet"
                ) : (
                  <div>
                    {info.gdacs_severity.name} - {info.gdacs_severity.value}
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.regions}>
                  <Typography>regions</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                {info.regions.map((r, idx) => (
                  <div key={`r-${r.id}-${idx}`}>
                    {r.name} - {r.country.name} {r.country.continent.name}
                  </div>
                ))}
                {info.countries.map(c => c.name).join()}
              </TableCell>
            </TableRow>
            {dateFields.map((f, idx) => (
              <TableRow key={`date-field-${idx}`}>
                <TableCell>
                  <Tooltip
                    title={
                      <div dangerouslySetInnerHTML={{ __html: legends[f] }} />
                    }
                  >
                    <Typography>{f}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {info[f] !== null && moment(info[f]).format(MOMENT_FORMAT)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Tooltip title={legends.categories}>
                  <Typography>categories</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                {info.categories.map((c, idx) => (
                  <div key={`categ-field-${idx}`}>
                    {c.name}: {c.value}
                  </div>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.urgency}>
                  <Typography>urgency</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <div>
                  {info.urgency.name}: {info.urgency.value}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.severity}>
                  <Typography>severity</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <div>
                  {info.severity.name}: {info.severity.value}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.certainty}>
                  <Typography>certainty</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <div>
                  {info.certainty.name}: {info.certainty.value}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.hazard_classifications}>
                  <Typography>Hazard Classifications</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                {info.hazard_classifications.map((c, idx) => (
                  <div key={`hazard-field-${idx}`}>
                    <strong>{c.name}: </strong> {c.description}
                  </div>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.headline}>
                  <Typography>headline</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>{info.headline}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.description}>
                  <Typography>description</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <div dangerouslySetInnerHTML={{ __html: info.description }} />
              </TableCell>
            </TableRow>
            {info.hasOwnProperty("resources") && (
              <TableRow>
                <TableCell>resources</TableCell>
                <TableCell>
                  {info.resources.map((r, idx) => (
                    <div key={`dialog-resources-${idx}`}>
                      <a href={r.uri} target="_blank" rel="noopener noreferrer">
                        {r.uri}
                      </a>
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Tooltip title={legends.web}>
                  <Typography>web</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>{info.web}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <div>
                  {this.state.isAddCountry 
                    ? (<Button onClick={this.handleCountriesList}
                        variant="outlined"
                        color="secondary">add new country</Button>)
                    : (<Button onClick={this.handleClearCountriesList}
                        variant="outlined"
                        color="secondary">hide</Button>)
                  }
                </div>
                {this.state.countriesList.length > 0 && <div><hr></hr></div>}
                <div>
                  {this.state.countriesList.map((country,idx) => 
                    <Chip key={`country-add-list-${idx}`} 
                      label={country.name}
                      onClick={()=>this.handleUpdateCountryID(country.id)} />)}
                </div>
              </TableCell>
              <TableCell>
                {info.countries.map((country,idx) => 
                  <InfoCountry key={`country-${idx}`} 
                    country={country} 
                    regions={info.regions} 
                    regionList={this.state.regionList}
                    regionsID={this.state.regionsID}
                    actions={actions} />)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <SystemParameterTable info={info} />
      </DialogContent>
    );
  }
}

DialogDataInfo.propTypes = {
  info: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

export default DialogDataInfo;
