// http://nominatim.org/release-docs/latest/api/Overview/
export const NOMINATIM_URL = "https://nominatim.openstreetmap.org/";
export const NOMINATIM_LOOKUP = "lookup?format=json&addressdetails=1&";
export const NOMINATIM_API_SEARCH = "search?format=json&accept-language=en-US&zoom=[0-16]&limit=8&addressdetails=1&q=";
export const NOMINATIM_API_REVERSE = "reverse?format=json&zoom=10&";

/*
const NOMINATIM_API_SEARCH_ONE =
  "search?format=json&limit=1&addressdetails=1&q=";
*/
