import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import List from "@material-ui/core/List";

import green from "@material-ui/core/colors/green";
// leaflet
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import {
  MAP_PARAMS,
  BASE_LAYER,
  /*
  GEOJSON_STYLE,
  GEOJSON_MARKER_POINT,
  */
  tableFeature
} from "../../lib/utils";

import Continents from "./sections/Continents";
import { NOMINATIM_URL, NOMINATIM_LOOKUP } from "../../api/osm";

const ICON = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

const styles = theme => ({
  bigMap: {
    height: "100vh",
    width: "100%"
  },
  nested: {
    paddingLeft: theme.spacing(6)
  },
  info: {
    color: green[500]
  }
});

async function getOpenData(urlNominatim) {
  let item = await fetch(urlNominatim).then(res => res.json());
  return item;
}

class BaseMap extends Component {
  componentDidMount() {
    const map = L.map(`map-${this.props.identifier}`).setView(
      MAP_PARAMS.center,
      MAP_PARAMS.zoom
    );

    L.tileLayer(BASE_LAYER.url, {
      maxZoom: BASE_LAYER.maxZoom,
      attribution: BASE_LAYER.attribution
    }).addTo(map);

    this.map = map;
    this.layerInfo = new Map();
  }

  componentWillUnmount() {
    this.map = null;
  }

  render() {
    const { classes, identifier, alertsTree, actions } = this.props;
    actions.addInfoLayer = this.addInfoLayer;
    return (
      <Grid container>
        <Grid item xs={5} md={3}>
          <Continents alertsTree={alertsTree} actions={actions} />
        </Grid>
        <Grid item xs={7} md={9}>
          <div id={`map-${identifier}`} className={classes.bigMap} />
        </Grid>
      </Grid>
    );
  }

  addInfoLayer = async info => {
    // console.log(info)
    if (this.layerInfo.has(info.identifier)) {
      const l = this.layerInfo.get(info.identifier);
      this.map.removeLayer(l.layer);
      this.layerInfo.delete(info.identifier);
      return;
    }
    if (info.hasOwnProperty("areas")) {
      try {
        const leafletLayer = await this.addFeatureCollection(info);

        if (leafletLayer) {
          leafletLayer.addTo(this.map);
          const l = {
            key: info.identifier,
            // name: info.region.name,
            layer: leafletLayer
          };
          this.layerInfo.set(info.identifier, l);
          this.map.fitBounds(leafletLayer.getBounds());
        }
      } catch (err) {
        console.log("errore layer...", info, err.message);
      }
    }
  };

  getData = async urls => {
    return await Promise.all(urls.map(url => getOpenData(url)));
  };

  addFeatureCollection = async info => {
    const areas = info.areas;
    if (areas.features.length < 1) return null;
    let layers = [];
    let urls = [];
    areas.features.forEach(feature => {
      if (feature.geometry !== null) {
        const l = L.geoJSON(feature.geometry).bindPopup(
          tableFeature(feature.properties)
        );
        layers.push(l);
      } else {
        if (feature.properties.osm_id.length > 3) {
          const urlNominatim = `${NOMINATIM_URL}${NOMINATIM_LOOKUP}osm_ids=${
            feature.properties.osm_id
          }`;
          urls.push(urlNominatim);
        }
      }
    });
    if (urls.length > 0) {
      const items = await this.getData(urls);
      items.forEach(item => {
        if (item.length === 1) {
          const lm = L.marker([item[0].lat, item[0].lon], {
            icon: ICON
          }).bindPopup(item[0].display_name);
          layers.push(lm);
        }
      });
    }
    if (layers.length > 0) return L.featureGroup(layers);
    return null;
  };

  zoomToLayer = info => {
    const key = info.identifier;
    const layer = this.layers.filter(l => l.key === key);
    if (layer.length === 1) {
      // zoom the map to the polygon: this.map.fitBounds(polygon.getBounds());
      try {
        this.map.fitBounds(layer[0].layer.getBounds());
      } catch (err) {
        alert(err.message);
        console.log("errore...", err.message);
      }
    } else {
      alert("Invalid LatLng object, layer not created");
    }
  };
}

BaseMap.propTypes = {
  classes: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  alertsTree: PropTypes.object.isRequired
};

export default withStyles(styles)(BaseMap);
