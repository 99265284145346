import React, { Component } from "react";
import withRoot from "../withRoot";
import LoginPage from "../views/LoginPage/LoginPage";
import LandingPage from "../views/LandingPage/LandingPage";
import { userAuth, getAllData } from "../api/api.js";
// import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";

import ErrorBoundary from "./ErrorBoundary";

const KEY_CURRENT_USER = "currentUser";

class App extends Component {
  state = {
    user: { auth: false, username: "" },
    allData: {},
    loading: false
  };

  componentDidMount() {
    if (localStorage.hasOwnProperty(KEY_CURRENT_USER)) {
      const currentUser = localStorage.getItem(KEY_CURRENT_USER);
      const user = JSON.parse(currentUser);
      this._apiAllData(user);
    }
  }

  render() {
    const { user, allData, loading } = this.state;
    const actions = {
      login: this._userLogin,
      logout: this._userLogout
    };
    if (!user.auth)
      return <LoginPage user={user} actions={actions} loading={loading} />;
    return (
      <ErrorBoundary>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <LandingPage user={user} actions={actions} allData={allData} />
        </MuiPickersUtilsProvider>
      </ErrorBoundary>
    );
  }

  _userLogin = async (username, password) => {
    this.setState({ loading: true, user: { auth: false } });
    const user = await userAuth(username, password);
    if (user.errors === null) {
      localStorage.setItem(KEY_CURRENT_USER, JSON.stringify(user));
      this._apiAllData(user);
    } else {
      this.setState({ user, loading: false });
    }
  };

  _userLogout = _ => {
    localStorage.removeItem(KEY_CURRENT_USER);
    this.setState({ user: { auth: false }, allData: {}, loading: false });
  };

  _apiAllData = async user => {
    this.setState({ loading: true });
    const allData = await getAllData(user);
    this._checkAllData(user, allData);
  };

  _checkAllData = (user, allData) => {
    if (allData.hasOwnProperty("info")) {
      if (allData.info.length === allData.apiInfo.length) {
        this.setState({ user, allData, loading: false });
      }
    } else {
      this.setState({
        user: { errors: "api error, data not valid" },
        loading: false
      });
    }
  };
}

export default withRoot(App);
