import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary  from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Infos from "../../Infos/Infos";

import { getInfobyIdentifier } from "../../../api/api.js";

function ListItem({ item, user, infoToValidate, actions, allData,expanded }) {
  const [info, setInfo] = React.useState(false);  
  
  if(!info && expanded){
    getInfobyIdentifier(user, item).then((resp) => setInfo(resp) );
  }

  return (    
    <div>
      {info !== null && (
        <Infos
          jsonInfos={[info]}
          identifier=""
          actions={actions}
          allData={allData}
          infoToReview={infoToValidate}
        />
      )}
    </div>
  );
}



function ListContinent({ continent, infoToValidate, user, actions, allData }) {
  // aggiungere raggruppamento
  const [oneInfo, setOneInfo] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = ()=>(event, newExpanded) => {
    setExpanded(newExpanded);
  };

  React.useEffect(() => {
    let infoGrouped = [
      ...new Set(
        infoToValidate
          .filter((i) => i.info.status === "AP")
          .filter((i) => i.info.countries[0].continent.name === continent)
          .map((i) => i.info.pk)
      ),
    ];
    setOneInfo(infoGrouped)    
  }, []);
  
  return (
    <ExpansionPanel style={{ marginLeft: 10 }} onChange={handleChange(!expanded)}>
      <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />} >
        <Typography variant="h5">{continent}</Typography>
      </ExpansionPanelSummary >
      <AccordionDetails style={{ flexDirection: "column" }} >
          {oneInfo.map((i,idx) => (
            <ListItem
            key={`toUpdate-${idx}`}
            item={i}
            user={user}
            actions={actions}
            allData={allData}
            infoToValidate={infoToValidate}              
            expanded={expanded}       
          />
          ))}
      </AccordionDetails>
    </ExpansionPanel>
  );
}

export default function InfoToUpdate({
  infoToValidate,
  user,
  actions,
  allData,
}) {
  if (infoToValidate.filter((i) => i.info.status === "AP").length < 1)
    return null;
  const [continent, setContinent] = React.useState([]);
  React.useEffect(() => {
    setContinent(
      Array.from(
        new Set(
          infoToValidate
            .filter((i) => i.info.status === "AP")
            .map((i) => i.info.countries[0].continent.name)
        )
      )
    );
  }, []);
  return (
    <div style={{ padding: 10 }}>
      <ExpansionPanel>
        <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">TO REVIEW</Typography>
        </ExpansionPanelSummary >
        <AccordionDetails style={{ flexDirection: "column" }}>
          {continent.map((c, idx) => (
            <ListContinent              
              key={`c-${idx}`}
              continent={c}
              user={user}
              actions={actions}
              allData={allData}
              infoToValidate={infoToValidate}                   
            />
          ))}
        </AccordionDetails>
      </ExpansionPanel>
    </div>
  );
}

/*
function ListItem({ item, user, infoToValidate, actions, allData }) {
  const [info, setInfo] = React.useState(null);
  React.useEffect(() => {
    getInfobyIdentifier(user, item.info.pk).then((resp) => setInfo(resp));
  }, []);
  return (
    <ExpansionPanel style={{ marginLeft: 10 }}>
      <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2">
          {info !== null && `${info.event}`}
        </Typography>
      </AccordionSummary >
      <AccordionDetails style={{ flexDirection: "column" }}>
        {info !== null && (
          <Infos
            jsonInfos={[info]}
            identifier=""
            actions={actions}
            allData={allData}
            infoToReview={infoToValidate}
          />
        )}
      </AccordionDetails>
    </ExpansionPanel>
  );
}

*/

/*
     <ExpansionPanel>
        <AccordionSummary  expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            TO REVIEW:{" "}
            {infoToValidate.filter((i) => i.info.status === "AP").length}
          </Typography>
        </AccordionSummary >
        <AccordionDetails style={{ flexDirection: "column" }}>
          {continent.map((c, idx) => (
            <div key={`c-${idx}`}>
              <Typography variant="h5">{c}</Typography>
              {infoToValidate
                .filter((i) => i.info.status === "AP")
                .filter((i) => i.info.countries[0].continent.name === c)
                .map((i, idx) => (
                  <ListItem
                    key={`toUpdate-${idx}`}
                    item={i}
                    user={user}
                    actions={actions}
                    allData={allData}
                    infoToValidate={infoToValidate}
                  />
                ))}
            </div>
          ))}
        </AccordionDetails>
      </ExpansionPanel>
*/
