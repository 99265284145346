import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  // CardActions,
  Typography,
  // Link
} from "@material-ui/core";
import { CHANGELOGS } from "../../data/changelogs.js";

function LogItem({ item }) {
  return (
    <CardContent>
      <Typography>v{item.version}</Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        {item.text}
      </Typography>
    </CardContent>
  );
}

function Changelog() {
  return (
    <Card>
      <CardHeader title="Changelog" />
      {CHANGELOGS.map((item, idx) => (
        <LogItem key={`log-${idx}`} item={item} />
      ))}
    </Card>
  );
}

export default Changelog;
