import React, { Component } from "react";
import PropTypes from "prop-types";
// import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  }
});

class AddEnd extends Component {
  state = {
    headline: "",
    description: ""
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const { nextStep, errors } = this.props;
    return (
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        {errors !== null ? (
          <Paper elevation={1} style={{ padding: 20 }}>
            <Typography variant="h5" component="h3">
              Error!
            </Typography>
            <br />
            <Typography color="error" variant="caption" gutterBottom>
              {JSON.stringify(errors)}
            </Typography>
          </Paper>
        ) : (
          <Typography variant="h5" component="h3">
            new info added!
          </Typography>
        )}

        <br />
        <br />
        <Button
          onClick={() => nextStep(0, {})}
          variant="outlined"
          color="primary"
        >
          add new
        </Button>
      </div>
    );
  }
}

AddEnd.propTypes = {
  classes: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default withStyles(styles)(AddEnd);
