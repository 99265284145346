import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// leaflet
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L, { Marker } from "leaflet";
import "leaflet-draw";

import {
  MAP_PARAMS,
  BASE_LAYER,
  tableFeature,
  setOsmSingleArea
  /* GEOJSON_STYLE,
  GEOJSON_MARKER_POINT,
  tableFeature */
} from "../../lib/utils";

import FeaturesBox from "./FeaturesBox";

import { postInfoAreas, deleteInfoAreas } from "../../api/api.js";

import {
  NOMINATIM_URL,
  NOMINATIM_LOOKUP,
  NOMINATIM_API_REVERSE
} from "../../api/osm";

const styles = theme => ({
  dialogContainer: {
    position: "relative"
  },
  dialogMap: {
    height: "60vh",
    width: "100%"
  },
  featureBox: {
    zIndex: 1000,
    position: "absolute",
    top: 10,
    left: 60,
    background: "#fff",
    padding: 3,
    border: "1px solid grey",
    borderRadius: 10
  }
});

const ICON = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

const drawnItems = new L.FeatureGroup();

Marker.prototype.options.icon = ICON;

async function fetchAllNominatim(promises) {
  return await Promise.all(promises).then(results => results);
}

async function fetchNominatim(url) {
  return await fetch(url).then(res => res.json());
}

class DialgoMap extends Component {
  state = { errors: false, features: [] };
  componentDidMount() {
    this._createMap();
  }

  componentWillUnmount() {
    this.map = null;
  }

  render() {
    const { classes, info } = this.props;
    const actionsArea = {
      delete: this.handleDeleteArea
    };
    return (
      <div className={classes.dialogContainer}>
        {this.state.errors && (
          <div style={{ padding: 10 }}>
            <Typography color="error" variant="h6" gutterBottom>
              erros on geometries
            </Typography>
          </div>
        )}
        <div
          id={`map-dialog-${info.identifier}`}
          className={classes.dialogMap}
        />
        {this.state.features.length > 0 && (
          <div className={classes.featureBox}>
            <FeaturesBox
              features={this.state.features}
              actionsArea={actionsArea}
            />
          </div>
        )}
      </div>
    );
  }

  handleDeleteArea = idx => {
    try {
      const idArea = this.state.features[idx].feature.id;
      const layer = this.state.features[idx].layer;
      deleteInfoAreas(this.props.user, this.props.info.identifier, idArea);
      this.map.removeLayer(layer);
      this.props.actions.reloadInfo(this.props.info.identifier);
      let features = [...this.state.features];
      features.splice(idx, 1);
      this.setState({ features });
    } catch (error) {
      console.log(error);
    }
  };

  addFeatureCollection = async info => {
    const geoJson = info.areas;
    if (geoJson !== null) {
      if (geoJson.hasOwnProperty("features")) {
        let nmLayers = [];
        const nmUrlLayers = [];
        geoJson.features.forEach(feature => {
          if (!feature.hasOwnProperty("is_parameter_area")) {
            if (feature.geometry !== null) {
              const geoLayer = L.geoJSON(feature.geometry)
                .bindPopup(tableFeature(feature.properties))
                .addTo(this.map);
              nmLayers.push({ feature, layer: geoLayer });
            } else {
              if (feature.properties.osm_id.length > 3) {
                const urlNominatim = `${NOMINATIM_URL}${NOMINATIM_LOOKUP}osm_ids=${
                  feature.properties.osm_id
                }`;
                nmUrlLayers.push({ feature, url: urlNominatim });
              }
            }
          }
        });
        if (nmUrlLayers.length > 0) {
          const promises = nmUrlLayers.map(n =>
            fetch(n.url).then(res => res.json())
          );
          const all = await fetchAllNominatim(promises);
          const l = all.map((a, idx) => {
            const item = a[0];
            const markerLayer = L.marker([item.lat, item.lon], {
              icon: ICON
            })
              .bindPopup(item.display_name)
              .addTo(this.map);
            const l = { feature: nmUrlLayers[idx].feature, layer: markerLayer };
            return l;
          });
          nmLayers = [...nmLayers, ...l];
        }
        this.setState({ features: nmLayers });
      }
    }
  };

  _createMap = _ => {
    const map = L.map(`map-dialog-${this.props.info.identifier}`).setView(
      MAP_PARAMS.center,
      MAP_PARAMS.zoom
    );
    L.tileLayer(BASE_LAYER.url, {
      maxZoom: BASE_LAYER.maxZoom,
      attribution: BASE_LAYER.attribution
    }).addTo(map);

    // Draw

    map.addLayer(drawnItems);
    const drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        marker: { icon: Marker.prototype.options.icon },
        circlemarker: false
      },
      edit: {
        featureGroup: drawnItems,
        edit: false,
        remove: false
      }
    });
    map.addControl(drawControl);
    map.on(L.Draw.Event.CREATED, e => {
      const type = e.layerType;
      const layer = e.layer;
      const geojson = layer.toGeoJSON();
      if (type === "marker") {
        this._addAsyncMarker(geojson);
      } else {
        this._addAsyncPolygon(type, layer, geojson);
      }
    });
    // end Draw

    this.map = map;
    this.addFeatureCollection(this.props.info);
  };

  _addAsyncPolygon = async (type, layer, geojson) => {
    const centroid =
      type !== "circle" ? layer.getBounds().getCenter() : layer.getLatLng();
    let iGeo = await fetchNominatim(
      `${NOMINATIM_URL}${NOMINATIM_API_REVERSE}lat=${centroid.lat}&lon=${
        centroid.lng
      }`
    );
    if (!iGeo.hasOwnProperty("error")) {
      drawnItems.addLayer(layer);
      const geometry = geojson.geometry;
      iGeo["geom"] = {
        type: "GeometryCollection",
        geometries: [geometry]
      };
      this._addAreaOsm(layer, iGeo);
    }
  };

  _addAsyncMarker = async geojson => {
    const coord = {
      lat: geojson.geometry.coordinates[1],
      lng: geojson.geometry.coordinates[0]
    };
    const iMarker = await fetchNominatim(
      `${NOMINATIM_URL}${NOMINATIM_API_REVERSE}lat=${coord.lat}&lon=${
        coord.lng
      }`
    );
    if (!iMarker.hasOwnProperty("error")) {
      const layer = L.marker([iMarker.lat, iMarker.lon], {
        icon: ICON
      })
        .bindPopup(iMarker.display_name)
        .addTo(this.map);

      this._addAreaOsm(layer, iMarker);
    }
  };

  _addAreaOsm = async (layer, item) => {
    const osmdb = setOsmSingleArea(item);
    const resp = await postInfoAreas(
      this.props.user,
      this.props.info.identifier,
      osmdb
    );
    if (resp.hasOwnProperty("id")) {
      const feature = { feature: resp, layer: layer };
      this.setState({ features: [...this.state.features, feature] });
      this.props.actions.reloadInfo(this.props.info.identifier);
    }
  };
}

DialgoMap.propTypes = {
  classes: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired
};

export default withStyles(styles)(DialgoMap);
