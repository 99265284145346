import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import RadioIcon from "@material-ui/icons/RadioButtonUnchecked";
import blue from "@material-ui/core/colors/blue";

import ListComponent from "./ListComponent.js";
// import Regions from "./Regions";
import Layers from "./Layers.js";
import StateHandler from "./StateHandlers.js";

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  }
});

const Country = ({ item, classes, open, click, actions }) => {
  const listIcon = <RadioIcon style={{ color: blue[500] }} />;
  return (
    <React.Fragment>
      <ListComponent
        classes={classes}
        click={click}
        listIcon={listIcon}
        listText={item[0]}
        typoText="subtitle1"
        open={open}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {/* <Regions mapRegions={item[1]} actions={actions} /> */}
        <Layers jsonInfos={item[1]} actions={actions} classes={classes} />
      </Collapse>
    </React.Fragment>
  );
};

Country.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired
};

const WithStateHandler = StateHandler(Country);

const Countries = ({ mapCountries, classes, actions }) => {
  // const countries = Array.from(mapCountries.entries());
  const countries = Object.entries(mapCountries).sort();
  return (
    <List component="div" disablePadding>
      {countries.map((c, idx) => (
        <WithStateHandler
          key={`country-${idx}`}
          item={c}
          classes={classes}
          actions={actions}
        />
      ))}
    </List>
  );
};

Countries.propTypes = {
  classes: PropTypes.object.isRequired,
  mapCountries: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

export default withStyles(styles)(Countries);
