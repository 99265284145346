import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import deepOrange from "@material-ui/core/colors/deepOrange";
import green from "@material-ui/core/colors/green";

import blue from "@material-ui/core/colors/blue";
import yellow from "@material-ui/core/colors/yellow";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import InfoIcon from "@material-ui/icons/Info";
import SaveAltIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import UnassignIcon from "@material-ui/icons/SettingsBackupRestore";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

import InfoTable from "./InfoTable.js";

const mapInfoStatus = new Map();
mapInfoStatus.set("UN", "unassigned");
mapInfoStatus.set("AP", "approved");
mapInfoStatus.set("DI", "deleted");

const styles = {
  top: {
    marginTop: 2,
  },
  greenAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: green[500],
  },
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500],
  },
  blueAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: blue[500],
  },
  rootInfoTable: {
    flexGrow: 1,
  },
};

class ListInfoTable extends Component {
  state = {
    open: false,
  };

  handleClick = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  render() {
    const {
      classes,
      item,
      identifier,
      actions,
      allData,
      infoToReview,
    } = this.props;
    const lblRegions = item.hasOwnProperty("regions")
      ? item.regions.map((r) => r.name).join(", ")
      : "";
    const lblCountries = item.hasOwnProperty("countries")
      ? item.countries.map((c) => c.name).join(", ")
      : "";
    const avatarColor =
      item.status === "UN"
        ? classes.blueAvatar
        : item.status === "AP"
        ? classes.greenAvatar
        : classes.orangeAvatar;
    const infoUpdated = infoToReview.filter(
      (i) => i.info.pk === item.identifier
    );
    const source = item.web ? ` [source: ${item.web}]` : ''
    return (
      <React.Fragment>
        <ListItem button>
          {infoUpdated.length < 1 ? (
            <Avatar className={avatarColor} onClick={this.handleClick}>
              {item.status}
            </Avatar>
          ) : (
            <Avatar className={classes.orangeAvatar} onClick={this.handleClick}>
              RE
            </Avatar>
          )}
          <ListItemText
            onClick={this.handleClick}
            primary={item.event}
            secondary={
              lblRegions.length > 1
                ? `[${lblRegions}, ${lblCountries}] ${source}`
                : `[${lblCountries}] ${source}`
            }
          />
          <React.Fragment>
            <Tooltip title="View" placement="top">
              <IconButton
                onClick={() => actions.viewInfo(item, identifier)}
                aria-label="View"
              >
                <InfoIcon style={{ color: blue[500] }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit" placement="top">
              <IconButton
                style={{ marginRight: 15 }}
                onClick={() => actions.editInfo(item, identifier)}
                aria-label="Edit"
              >
                <EditIcon style={{ color: yellow[700] }} />
              </IconButton>
            </Tooltip>
          </React.Fragment>
          {infoUpdated.length < 1 && (
            <React.Fragment>
              {item.status === "UN" ? (
                <React.Fragment>
                  <Tooltip
                    title={
                      item.gdacs_severity === null
                        ? "Select gdacs severity"
                        : "Approve"
                    }
                    placement="top"
                  >
                    <span>
                      <IconButton
                        onClick={() => actions.approveInfo(item, identifier)}
                        aria-label="Add"
                        color="secondary"
                        disabled={item.gdacs_severity === null ? true : false}
                      >
                        <SaveAltIcon />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <IconButton
                    onClick={() => actions.deleteInfo(item, identifier)}
                    aria-label="Delete"
                    style={{
                      color: red[500],
                    }}
                  >
                    <Tooltip title="Delete" placement="top">
                      <DeleteIcon />
                    </Tooltip>
                  </IconButton>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Tooltip title="Undo action" placement="top">
                    <IconButton
                      onClick={() => actions.unassignInfo(item, identifier)}
                      aria-label="Undo"
                      style={{ color: orange[500] }}
                    >
                      <UnassignIcon />
                    </IconButton>
                  </Tooltip>
                  <span>status: {mapInfoStatus.get(item.status)}</span>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {this.state.open ? (
            <ExpandLess onClick={this.handleClick} />
          ) : (
            <ExpandMore onClick={this.handleClick} />
          )}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <Grid container className={classes.rootInfoTable}>
            <InfoTable
              info={item}
              identifier={identifier}
              actions={actions}
              allData={allData}
              infoToReview={infoToReview}
            />
          </Grid>
        </Collapse>
      </React.Fragment>
    );
  }
}

class Infos extends Component {
  render() {
    const jsonInfos = { info: this.props.jsonInfos };
    const { classes, identifier, actions, allData, infoToReview } = this.props;
    return (
      <div className={classes.top}>
        <List disablePadding={true}>
          {jsonInfos.info.map((item, idx) => (
            <ListInfoTable
              key={`ii-${idx}`}
              item={item}
              identifier={identifier}
              actions={actions}
              allData={allData}
              classes={classes}
              infoToReview={infoToReview}
            />
          ))}
        </List>
      </div>
    );
  }
}

Infos.propTypes = {
  classes: PropTypes.object.isRequired,
  jsonInfos: PropTypes.array.isRequired,
  identifier: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired,
  infoToReview: PropTypes.array.isRequired,
};

export default withStyles(styles)(Infos);
