import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  loginContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  loginItem: {
    marginTop: 40,
    width: 380
  }
});

class ErrorPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.loginContainer}>
        <Card className={classes.loginItem}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Something went wrong
            </Typography>
            <Typography variant="caption" component="p">
              version {process.env.REACT_APP_VERSION}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              onClick={this.handleReload}
              variant="outlined"
              color="secondary"
            >
              Reload
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
  handleReload = _ => window.location.reload();
}

export default withStyles(styles)(ErrorPage);
