import React from "react";

const ParamExtra = ({ extra }) => {
  if (typeof extra === "string") return extra;
  const ks = Object.keys(extra);
  return (
    <div>
      {ks.map((k, idx) => (
        <div key={`extra-${idx}`}>
          {k}: {extra[k]}
        </div>
      ))}
    </div>
  );
};

export default ParamExtra;
