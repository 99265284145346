import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import RadioIcon from "@material-ui/icons/RadioButtonUnchecked";
import blue from "@material-ui/core/colors/blue";

import ListComponent from "./ListComponent.js";
// import Regions from "./Regions";
// import Infos from "./Infos";
import Infos from "../../Infos/Infos";
import StateHandler from "./StateHandlers.js";

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  }
});

const Country = ({
  item,
  classes,
  open,
  click,
  actions,
  allData,
  infoToReview
}) => {
  const listIcon = <RadioIcon style={{ color: blue[500] }} />;
  return (
    <React.Fragment>
      <ListComponent
        classes={classes}
        click={click}
        listIcon={listIcon}
        listText={`${actions.getNumActivity("country", item[0])} ${item[0]}`}
        typoText="h6"
        open={open}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {/* <Regions mapRegions={item[1]} actions={actions} allData={allData} /> */}
        <Infos
          jsonInfos={item[1]}
          identifier=""
          actions={actions}
          allData={allData}
          infoToReview={infoToReview}
        />
      </Collapse>
    </React.Fragment>
  );
};

Country.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

const WithStateHandler = StateHandler(Country);

const Countries = ({
  mapCountries,
  classes,
  actions,
  allData,
  infoToReview
}) => {
  const countries = Object.entries(mapCountries).sort(); // Array.from(mapCountries.entries());
  return (
    <List component="div" disablePadding>
      {countries.map((c, idx) => (
        <WithStateHandler
          key={`country-${idx}`}
          item={c}
          classes={classes}
          actions={actions}
          allData={allData}
          infoToReview={infoToReview}
        />
      ))}
    </List>
  );
};

Countries.propTypes = {
  classes: PropTypes.object.isRequired,
  mapCountries: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired,
  infoToReview: PropTypes.array.isRequired
};

export default withStyles(styles)(Countries);
