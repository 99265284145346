import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

import imageLogin from "./login_400.png";

const styles = theme => ({
  loginContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  loginItem: {
    marginTop: 40,
    width: 380
  },
  media: {
    height: 140
  }
});

class LoginPage extends Component {
  state = {
    uname: process.env.REACT_APP_USERNAME,
    upsw: process.env.REACT_APP_PSW
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  render() {
    const { classes, user, loading } = this.props;
    return (
      <div className={classes.loginContainer}>
        <Card className={classes.loginItem}>
          <CardMedia
            className={classes.media}
            image={imageLogin}
            title="User login - Photo by jonathan Ford on Unsplash"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {process.env.REACT_APP_TITLE}
            </Typography>
            <Typography variant="caption" component="p">
              version {process.env.REACT_APP_VERSION}
            </Typography>
            <form noValidate autoComplete="off" onSubmit={this.handleLogin}>
              <TextField
                id="u-name"
                label="Username"
                value={this.state.uname}
                onChange={this.handleChange("uname")}
                margin="normal"
                variant="outlined"
              />
              <TextField
                id="u-psw"
                label="Password"
                type="password"
                value={this.state.upsw}
                onChange={this.handleChange("upsw")}
                onKeyPress={ev => {
                  if (ev.key === "Enter") {
                    this.handleLogin();
                  }
                }}
                margin="normal"
                variant="outlined"
              />
            </form>
            {user.hasOwnProperty("errors") && (
              <div>
                <Typography color="error" variant="caption" gutterBottom>
                  {JSON.stringify(user.errors)}
                </Typography>
              </div>
            )}
            {loading && (
              <div>
                <Typography variant="overline" gutterBottom>
                  wait... retrieving data from backend
                </Typography>
                <div>
                  <LinearProgress />
                </div>
              </div>
            )}
          </CardContent>
          <CardActions>
            {!loading && (
              <Button
                onClick={this.handleLogin}
                variant="outlined"
                color="secondary"
                disabled={
                  this.state.uname.length < 3 || this.state.upsw.length < 3
                    ? true
                    : false
                }
              >
                Login
              </Button>
            )}
          </CardActions>
        </Card>
      </div>
    );
  }

  handleLogin = _ => {
    this.props.actions.login(this.state.uname, this.state.upsw);
  };

  handleBackendSsl = _ => {
    const url = `${process.env.REACT_APP_CAP_HOST}/admin/`;
    window.open(url, "_blank");
  };
}

LoginPage.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

export default withStyles(styles)(LoginPage);
