import React, { Component } from "react";
import PropTypes from "prop-types";
// import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
// import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import InputDatePicker from "../../components/InputDatePicker";
import MultipleSelect from "../../components/MultipleSelect";
import SimpleSelect from "../../components/SimpleSelect";
import TreeMultipleSelect from "../TreeMultipleSelect";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  dateField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: 30,
    marginTop: 30
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
    /* width: 400 */
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  paper: {
    padding: theme.spacing(4),
    width: "100%"
  }
});

class AddInfo extends Component {
  state = {
    onset: "",
    expires: "",
    effective: "",
    categories: "",
    urgency: "",
    severity: "",
    certainty: "",
    event: "",
    headline: "",
    description: "",
    web: "",
    gdacs_severity_id: "1",
    status: "UN",
    hazard_classifications: "",
    treeSelectData: []
  };

  UNSAFE_componentWillMount() {
    if (this.props.data.hasOwnProperty("info")) {
      const {
        onset,
        expires,
        effective,
        event,
        headline,
        description,
        web
      } = this.props.data.info[0];
      this.setState({
        onset: onset,
        expires: expires,
        effective: effective,
        event: event,
        headline: headline,
        description: description,
        web: web
      });
    } else {
      const current = new Date().toJSON().substr(0, 16);
      this.setState({ onset: current, expires: current, effective: current });
    }
    this.data = {};
    let hazards = this.props.allData.info[10].map(info => ({ ...info, value: info.id, label: info.name, code: info.name }));
    this.setState({ treeSelectData: this.buildTreeFromArray(hazards) });
    // this.setState({ treeSelectData: hazards });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  LIST_COLORS = {
    "1": "#93B459",
    "2": "#5e84b2",
    "3": "#B14D4A",
    "4": "#F5BA31",
    "5": "#EA863E",
    "6": "#7f6696",
    "8": "#D5D5CF"
  };


  render() {
    const { classes, nextStep, data, allData } = this.props;
    const legends = allData.info[8];
    const fieldGdacs = allData.info[5].results;
    const hazards = allData.info[10].map(info => ({ ...info, code: info.name, value: info.name }));

    return (
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <form className={classes.container} noValidate autoComplete="off">
          <Paper className={classes.paper} elevation={1}>
            <div>
              <Typography color="primary" variant="h5" gutterBottom>
                {data.areas.map(a => a.description).join(`, `)}
              </Typography>
            </div>
            <div style={{ marinTop: 10, marginLeft: 10, marginBottom: 10 }}>
              <InputDatePicker
                setdate={this.handleChangeValueInputDate}
                currentDate={this.state.onset}
                label="onset"
              />
              {` `}
              <InputDatePicker
                setdate={this.handleChangeValueInputDate}
                currentDate={this.state.expires}
                label="expires"
              />
              {` `}
              <InputDatePicker
                setdate={this.handleChangeValueInputDate}
                currentDate={this.state.effective}
                label="effective"
              />
            </div>
            <div>
              <MultipleSelect
                label="categories"
                field="categories"
                values={allData.info[0]}
                getIds={this.handleComboIds}
              />
              <SimpleSelect
                label="urgency"
                field="urgency"
                values={allData.info[2]}
                getIds={this.handleComboIds}
              />
              <SimpleSelect
                label="severity"
                field="severity"
                values={allData.info[3]}
                getIds={this.handleComboIds}
              />
              <SimpleSelect
                label="certainty"
                field="certainty"
                values={allData.info[4]}
                getIds={this.handleComboIds}
              />
              <TreeMultipleSelect
                label="hazard classifications"
                field="hazard_classifications"
                tree={this.state.treeSelectData}
                flatArray={hazards}
                getIds={this.handleComboIds}
                colors={this.LIST_COLORS}                
              />
            </div>
            <div style={{ marginTop: 30, marginLeft: 10 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">status</FormLabel>
                <RadioGroup
                  aria-label="Status"
                  name="status"
                  value={this.state.status}
                  onChange={this.handleChangeRadioStatus}
                  row
                >
                  <FormControlLabel
                    value="UN"
                    control={<Radio />}
                    label={<Typography>Unassigned</Typography>}
                  />
                  <FormControlLabel
                    value="AP"
                    control={<Radio />}
                    label={<Typography>Approved</Typography>}
                  />
                  <FormControlLabel
                    value="DI"
                    control={<Radio />}
                    label={<Typography>Deleted</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ marginTop: 30, marginLeft: 10 }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">gdacs alert</FormLabel>
                <RadioGroup
                  aria-label="GDACS"
                  name="gdacs"
                  value={this.state.gdacs_severity_id}
                  onChange={this.handleChangeRadioGDACS}
                  row
                >
                  {fieldGdacs.map((f, idx) => (
                    <FormControlLabel
                      key={`f-gdacs-${idx}`}
                      value={`${f.id}`}
                      control={<Radio />}
                      label={
                        <Tooltip title={f.legend} placement="top">
                          <Typography>{f.name}</Typography>
                        </Tooltip>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>

            <div style={{ marginTop: 30 }}>
              <Tooltip title={legends.event}>
                <TextField
                  fullWidth
                  id="event"
                  label="Event"
                  className={classes.textField}
                  variant="outlined"
                  multiline
                  rows="3"
                  value={this.state.event}
                  onChange={this.handleChange("event")}
                  margin="normal"
                />
              </Tooltip>
              <Tooltip title={legends.headline}>
                <TextField
                  fullWidth
                  id="headline"
                  label="Headline"
                  className={classes.textField}
                  value={this.state.headline}
                  onChange={this.handleChange("headline")}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows="3"
                />
              </Tooltip>
              <Tooltip title={legends.description}>
                <TextField
                  fullWidth
                  id="description"
                  label="Description"
                  className={classes.textField}
                  value={this.state.description}
                  onChange={this.handleChange("description")}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows="3"
                />
              </Tooltip>
              <Tooltip title={legends.web}>
                <TextField
                  fullWidth
                  id="web"
                  label="Web"
                  className={classes.textField}
                  value={this.state.web}
                  onChange={this.handleChange("web")}
                  margin="normal"
                  variant="outlined"
                />
              </Tooltip>
            </div>
          </Paper>
        </form>
        <br />
        <br />
        <Button
          onClick={() => nextStep(0, { ...data, info: [] })}
          variant="outlined"
          color="primary"
        >
          prev
        </Button>
        <Button
          onClick={() => nextStep(2, { ...data, info: [{ ...this.state }] })}
          variant="outlined"
          color="secondary"
          style={{ marginLeft: 5 }}
          disabled={this.state.event.length > 2 ? false : true}
        >
          next
        </Button>
      </div>
    );
  };


  /*****Hazard Tree *******/

  buildTreeFromArray = (data) => {
    let idToNodeMap = data.reduce(function (map, node) {
      map[node.value] = node;
      return map;
    }, {});
    let root = []; //Initially set our loop to null

    //loop over data
    data.forEach(function (datum) {

      //each node will have children, so let's give it a "children" poperty
      datum.children = [];

      //add an entry for this node to the map so that any future children can
      //lookup the parent
      idToNodeMap[datum._id] = datum;

      //Does this node have a parent?
      if (!datum.parent) {
        //Doesn't look like it, so this node is the root of the tree
        root.push(datum);
      } else {
        //This node has a parent, so let's look it up using the id
        let parentNode = idToNodeMap[datum.parent];

        //Let's add the current node as a child of the parent node.
        parentNode.children.push(datum);
      }
    });    
    return root;
  };

  handleChangeValueInputDate = (label, date) => {
    this.setState({ [label]: date.toISOString() });
    // params[label] = date.toISOString();
  };

  handleChangeRadioStatus = event => {
    this.setState({ status: event.target.value });
  };

  handleChangeRadioGDACS = event => {
    this.setState({ gdacs_severity_id: event.target.value });
  };

  handleComboIds = (ids, field) => {
    this.setState({
      [`${field}_id`]: ids
    });
  };

}

AddInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

export default withStyles(styles)(AddInfo);
