import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import green from "@material-ui/core/colors/green";
// import orange from "@material-ui/core/colors/orange";

const ListComponent = ({
  classes,
  click,
  listIcon,
  listText,
  listTextSecondary,
  typoText,
  textAvatar,
  open
}) => (
  <React.Fragment>
    <ListItem className={classes.nested} button onClick={() => click()}>
      {textAvatar && (
        <Avatar style={{ width: 60, height: 45, backgroundColor: green[600] }}>
          <Tooltip
            title="[unassigend / approved / deleted]"
            placement="top-start"
          >
            <Typography variant="body2" style={{ color: "#fff" }} gutterBottom>
              {textAvatar}
            </Typography>
          </Tooltip>
        </Avatar>
      )}
      {listIcon && <ListItemIcon>{listIcon}</ListItemIcon>}
      <ListItemText
        primary={
          <Typography variant={typoText} gutterBottom style={{marginLeft:3}}>
            {listText}
          </Typography>
        }
        secondary={listTextSecondary}
      />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Divider />
  </React.Fragment>
);

ListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  click: PropTypes.func.isRequired,
  // listIcon: PropTypes.object.isRequired,
  listText: PropTypes.string.isRequired,
  typoText: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default ListComponent;
