import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
// import TextField from "@material-ui/core/TextField";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
// import RadioIcon from "@material-ui/icons/RadioButtonUnchecked";
// import AddIcon from "@material-ui/icons/AddCircle";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";

import MapParamTable from "../MapParamTable";

// import Select from "react-select";

// import { searchCountries, searchRegions } from "../../api/api.js";

import { setOsmCoordAreas } from "../../lib/utils";

// const options = [];

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  paper: {
    padding: theme.spacing(4)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 550
  }
});

/*
const CountryItem = ({ item, sel }) => {
  return (
    <ListItem button>
      <ListItemIcon>
        <RadioIcon />
      </ListItemIcon>
      <ListItemText
        onClick={() => sel(item)}
        primary={item.name}
        secondary={`${item.continent.name}`}
      />
    </ListItem>
  );
};
*/

/*
const CountryItems = ({ items, sel }) => {
  return (
    <List>
      {items.map((item, idx) => (
        <CountryItem key={`reg-${idx}`} item={item} sel={sel} />
      ))}
    </List>
  );
};
*/

/*
const RegionItem = ({ item, next }) => {
  return (
    <ListItem button>
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
      <ListItemText
        onClick={() => next(item)}
        primary={item.name}
        secondary={`${item.country.name} ${item.country.continent.name}`}
      />
    </ListItem>
  );
};
*/

/*
const RegionItems = ({ items, next }) => {
  return (
    <List>
      {items.map((item, idx) => (
        <RegionItem key={`reg-${idx}`} item={item} next={next} />
      ))}
    </List>
  );
};
*/

class SelectRegions extends Component {
  state = {
    response: { results: [] },
    listCountries: [],
    loading: false,
    countries: "",
    regions: "",
    regionsToAdd: [],
    countriesToAdd: [],
    value: "regions",
    selectedOption: null,
    osmResults: []
  };

  componentDidMount() {
    this.props.data.hasOwnProperty("regions") &&
      this.setState({ regionsToAdd: this.props.data.regions });
    this.props.data.hasOwnProperty("countries") &&
      this.setState({ countriesToAdd: this.props.data.countries });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleRadioChange = event => {
    this.setState({ value: event.target.value });
  };

  handleChangeSel = selectedOption => {
    this.setState({ selectedOption });
  };

  habdleInputSel = value => {
    this._getCountries4Sel(value);
  };

  render() {
    const { classes } = this.props;
    // const { listCountries, response, loading } = this.state;
    const actions = {
      updateOsmPlace: this.handleOsmPlace,
      component: "addnewinfo",
      paramsData: {
        user: this.props.user
      }
    };
    return (
      <Paper className={classes.paper} elevation={1}>
        <div style={{ marginBottom: 15 }}>
          <MapParamTable
            id={`map-addnew`}
            mapConfig={{ zoom: 3, height: 350 }}
            areas={{ areas: null }}
            actions={actions}
          />
        </div>
        <Grid container>
          <Grid item xs={10} />
          <Grid item xs={2}>
            <Button
              onClick={this.handleNext}
              variant="outlined"
              color="secondary"
              disabled={this.state.osmResults.length > 0 ? false : true}
            >
              next
            </Button>
          </Grid>
        </Grid>
        {/*
        <div>
          <FormControl component="fieldset">
            <FormLabel component="legend">Insert info by:</FormLabel>
            <RadioGroup
              aria-label="RegionsCountries"
              name="regionsCountries"
              value={this.state.value}
              onChange={this.handleRadioChange}
              row
            >
              <FormControlLabel
                value="countries"
                control={<Radio />}
                label="Countries"
              />
              <FormControlLabel
                value="regions"
                control={<Radio />}
                label="Regions"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <Grid container style={{ marginTop: 20 }} spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6" component="h3">
              Country
            </Typography>
            <div>
              <TextField
                id="country"
                label="Type Country name"
                variant="outlined"
                value={this.state.countries}
                onChange={this.handleSearchCountry("countries")}
                margin="normal"
              />
            </div>
            {listCountries.length > 0 && (
              <React.Fragment>
                <Typography variant="h5" component="h3">
                  <small>select one</small>
                </Typography>
                <CountryItems
                  items={listCountries}
                  sel={this.handleSelCountry}
                />
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={4}>
            {response.results.length > 0 && (
              <React.Fragment>
                <Typography variant="h6" component="h3">
                  Regions <small>select one</small>
                </Typography>
                <div style={{ maxHeight: 350, overflow: "auto" }}>
                  <RegionItems
                    items={response.results}
                    next={this.handleAddRegion}
                  />
                </div>
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={4}>
            {this.state.value === "regions" ? (
              <React.Fragment>
                <Typography variant="h6" component="h3">
                  Regions <small>selected</small>
                </Typography>
                <div style={{ marginBottom: 10 }}>
                  {this.state.regionsToAdd.map((r, idx) => (
                    <div key={`reg-${idx}`} style={{ padding: 8 }}>
                      <Typography variant="subtitle1">
                        {r.name} {r.country.name}{" "}
                        <Button
                          onClick={() => this.handleRemoveRegion(r)}
                          size="small"
                          variant="outlined"
                          color="primary"
                        >
                          remove
                        </Button>
                      </Typography>
                    </div>
                  ))}
                </div>
                <div>
                  <Button
                    onClick={this.handleNext}
                    variant="outlined"
                    color="secondary"
                    style={{ marginLeft: 5 }}
                    disabled={this.state.osmResults.length > 0 ? false : true}
                  >
                    next
                  </Button>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant="h6" component="h3">
                  Countries <small>selected</small>
                </Typography>
                <div style={{ marginBottom: 10 }}>
                  {this.state.countriesToAdd.map((c, idx) => (
                    <div key={`co-${idx}`} style={{ padding: 8 }}>
                      <Typography variant="subtitle1">
                        {c.name} {c.continent.name}{" "}
                        <Button
                          onClick={() => this.handleRemoveCountries(c)}
                          size="small"
                          variant="outlined"
                          color="primary"
                        >
                          remove
                        </Button>
                      </Typography>
                    </div>
                  ))}
                </div>
                <div>
                  <Button
                    onClick={this.handleNext}
                    variant="outlined"
                    color="secondary"
                    style={{ marginLeft: 5 }}
                    disabled={
                      this.state.countriesToAdd.length > 0 ? false : true
                    }
                  >
                    next
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
                  */}
      </Paper>
    );
  }

  handleOsmPlace = osmResults => {
    this.setState({ osmResults });
  };

  /*
  handleAddRegion = region => {
    const regionsToAdd = [
      ...this.state.regionsToAdd.filter(r => r.id !== region.id),
      region
    ];
    this.setState({ regionsToAdd });
  };
  */

  /*
  handleRemoveRegion = region => {
    const regionsToAdd = [
      ...this.state.regionsToAdd.filter(r => r.id !== region.id)
    ];
    this.setState({ regionsToAdd });
  };
  */

  /*
  handleRemoveCountries = country => {
    const countriesToAdd = [
      ...this.state.countriesToAdd.filter(c => c.id !== country.id)
    ];
    this.setState({ countriesToAdd });
  };
  */

  /*
  handleSearchCountry = name => event => {
    this.setState({
      [name]: event.target.value
    });
    if (event.target.value.length > 2) {
      this._getCountries(event.target.value);
    } else {
      this.setState({ listCountries: [], response: { results: [] } });
    }
  };
  */

  /*
  handleSelCountry = country => {
    if (this.state.value === "regions") {
      this._getRegions(country.id);
    } else {
      const countriesToAdd = [
        ...this.state.countriesToAdd.filter(c => c.id !== country.id),
        country
      ];
      this.setState({ countriesToAdd });
    }
  };
  */

  /*
  _getCountries = async item => {
    const response = await searchCountries(
      this.props.user,
      `?name__istartswith=${item}`
    );
    if (response.hasOwnProperty("results")) {
      this.setState({ listCountries: response.results });
    }
  };
  */
  /*
  _getCountries4Sel = async item => {
    const response = await searchCountries(
      this.props.user,
      `?name__istartswith=${item}`
    );
    if (response.hasOwnProperty("results")) {
      // this.setState({ listCountries: response.results });
      // console.log(response);
      const c = response.results;
      c.forEach(i => {
        const id = options.map(o => o.value);
        if (!id.includes(i.id)) options.push({ value: i.id, label: i.name });
      });
    }
  };
  */
 /*
  _getRegions = async id => {
    const response = await searchRegions(this.props.user, `?country__id=${id}`);
    if (response.hasOwnProperty("results")) {
      this.setState({ response, loading: false });
    }
  };
*/
  handleNext = _ => {
    this.props.nextStep(1, {
      // regions: this.state.regionsToAdd,
      // countries: this.state.countriesToAdd,
      // osm_results: this.state.osmResults,
      areas: setOsmCoordAreas(this.state.osmResults)
      /*
      areas: this.state.osmResults.map(osm => {
        return {
          description: osm.item.display_name,
          osm_id: `${osm.item.osm_type.slice(0, 1).toUpperCase()}${
            osm.item.osm_id
          }`,
          osm_address: osm.item.address
        };
      })
      */
    });
  };
}

SelectRegions.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  nextStep: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default withStyles(styles)(SelectRegions);
