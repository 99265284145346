import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from '@material-ui/core/MenuList';
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";


const MuiMenuItem = React.forwardRef((props, ref) => {
  return <MenuItem ref={ref} {...props} />;
});


const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
});

const checkBoxStyles = theme => ({
  root: {
    '&$checked': {
      color: 'black',
    },
  },
  checked: {},
})

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto"
    }
  }
};



const TreeItem = ({ item, idList, backgroundColors, handleList }) => {
  const [open, setOpen] = React.useState(false);
  let expand = () => {
    if (item.children.length) {
      return open ?
        (<ExpandLess onClick={() => setOpen(!open)} />) :
        (<ExpandMore onClick={() => setOpen(!open)} />);
    }
  }

  return (
    <MuiMenuItem key={item.id} value={item.id} name={item.name} style={{ "backgroundColor": backgroundColors[item.id] }}>
      <CustomCheckbox checked={idList && idList.indexOf(item.id) > -1} onChange={(e) => handleList(item)} />
      {expand()}
      <Tooltip title={item.description} placement="top">
        <ListItemText primary={item.name} onClick={() => setOpen(!open)} />
      </Tooltip>      
      {item.children.length ?
        (<Collapse in={open} timeout="auto" unmountOnExit>
          <br />
          <MenuList children={item.children}>
            {item.children.map((subItem) => (
              <TreeItem
                key={subItem.id}
                item={subItem}
                idList={idList}
                backgroundColors={backgroundColors}
                handleList={handleList}
              />
            ))}
          </MenuList>
        </Collapse>
        ) : null
      }
    </MuiMenuItem >
  );
}

class TreeMultipleSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codes: [],
      backgroundColors: [],
      idList: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.tree.map(value => value.id).reduce((previous, current) => this.getBackgroundColor(this.props.colors, previous, current), 0);
    this.setState({ idList: this.props.idList || [], codes: this.props.flatArray.filter(value => this.props.idList && this.props.idList.includes(value.id)).map(value => value.code) });
  }


  handleChange = event => {
    const codes = this.props.flatArray
      .filter(value => event.target.value.includes(value.id))
      .map(value => value.code);
    this.setState({ codes: codes });
    this.props.getIds(event.target.value, this.props.field);
  };

  getBackgroundColor = (colors, previousIndex, id) => {
    if (!colors) {
      return "white";
    }
    let backgroundColors = this.state.backgroundColors;
    if (colors[id]) {
      backgroundColors[id] = colors[id];
    } else {
      if (previousIndex) {
        backgroundColors[id] = backgroundColors[previousIndex];
      } else {
        backgroundColors[id] = "white";
      }
    }
    this.setState({ backgroundColors: backgroundColors });
    return id;
  }

  handleList = (item) => {
    const ll = new Set([...this.state.idList]);
    if (ll.has(item.id)) {
      ll.delete(item.id);
      this.setState({ idList: Array.from(ll) });     
    } else {
      ll.add(item.id);
      this.setState({ idList: Array.from(ll) });
    }
    this.handleChange({ target: { value: Array.from(ll) } });
  }

  render() {
    const { classes, label, tree } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="select-multiple-checkbox">{label}</InputLabel>
        <Select
          multiple
          value={this.state.codes}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={selected => selected.join(", ")}
          MenuProps={MenuProps}>
          <MenuList>
            {tree.map((item) => (
              <TreeItem
                key={`h-${item.id}`}
                item={item}
                backgroundColors={this.state.backgroundColors}
                idList={this.state.idList}
                handleList={this.handleList}
              />
            ))}
          </MenuList>
        </Select>
      </FormControl>
    );
  }

}

TreeMultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  getIds: PropTypes.func.isRequired,
  flatArray: PropTypes.array.isRequired,
};

export default withStyles(styles)(TreeMultipleSelect);
