import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
// import FileCopyIcon from "@material-ui/icons/FileCopy";
// import SaveIcon from "@material-ui/icons/Save";
import red from "@material-ui/core/colors/red";

import Select from "react-select";

import MapParamTable from "./MapParamTable";
import ParamAreas from "./SystemParameter/ParamAreas";
import ParamExtra from "./SystemParameter/ParamExtra";
import InputDatePicker from "./InputDatePicker";

import {
  patchInfoParameters,
  postInfoParameters,
  deleteInfoParameters
  // postInfoParameterAreas,
  // deleteInfoParameterAreas
} from "../api/api.js";

import { isNumeric } from "../lib/utils";

const UNIT_DEFAULT = { value: 2, label: "number" };

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing(1)
  }
});

function checkResponse(response, errors, c) {
  if (!response.hasOwnProperty("id")) {
    const error = { error: response, field: c.system_parameter.value_name };
    errors.push(error);
  }
  return errors;
}

class EditValue extends React.Component {
  state = {
    valueName: {},
    valueUnit: {},
    valueQualitative: {},
    description: "",
    quantitative: "",
    params: {},
    allParams: [],
    allUnits: [],
    allQualitative: [],
    onset: null,
    expires: null,
    effective: null
    /* allRegions: [] */
  };
  UNSAFE_componentWillMount() {
    const allParams = this.props.allData.info[7].map(p => {
      return { value: p.id, label: p.value_name, qualitative: p.qualitative };
    });
    const allUnits = this.props.allData.info[9].map(u => {
      return { value: u.id, label: u.name };
    });
    let allQualitative = [];

    if (this.props.item.system_parameter.qualitative !== null) {
      if (Array.isArray(this.props.item.system_parameter.qualitative.type)) {
        allQualitative = this.props.item.system_parameter.qualitative.type.map(
          (q, idx) => {
            return { value: idx, label: q };
          }
        );
      }
    }
    const valueName = {
      value: this.props.item.system_parameter.id,
      label: this.props.item.system_parameter.value_name
    };
    const valueUnit = Object.assign({}, UNIT_DEFAULT);
    if (this.props.item.hasOwnProperty("unit")) {
      if (this.props.item.unit !== null) {
        valueUnit.value = this.props.item.unit.id;
        valueUnit.label = this.props.item.unit.name;
      }
    }
    let valueQualitative = {};
    if (Array.isArray(this.props.item.qualitative)) {
      allQualitative.forEach(q => {
        if (this.props.item.qualitative.includes(q.label)) {
          valueQualitative.value = q.value;
          valueQualitative.label = q.label;
        }
      });
    }
    // const allRegions = [];
    /*
    const allRegions = this.props.item.regions.map(r => {
      return { value: r.id, label: `${r.name} [${r.country.name}]` };
    });
    */
    this.setState({
      params: this.props.item,
      description: this.props.item.description,
      quantitative: this.props.item.quantitative || "",
      allParams,
      allUnits,
      allQualitative,
      /* allRegions, */
      valueName,
      valueUnit,
      valueQualitative
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.item.id !== this.state.params.id) {
      this.setState({
        params: nextProps.item,
        description: nextProps.item.description,
        quantitative: nextProps.item.quantitative || ""
      });
    }
  }

  handleChangeSelValueName = valueName => {
    const params = Object.assign({}, this.state.params);
    params.system_parameter.id = valueName.value;
    params.system_parameter.value_name = valueName.label;
    params.system_parameter.qualitative = valueName.qualitative;
    let allQualitative = [];
    if (valueName.qualitative !== null) {
      if (Array.isArray(valueName.qualitative.type)) {
        allQualitative = valueName.qualitative.type.map((q, idx) => {
          return { value: idx, label: q };
        });
      }
    } else {
      params.qualitative = null;
    }
    this.setState({ params, valueName, allQualitative }, this.handleOnBlur());
  };

  handleChangeSelValueUnit = valueUnit => {
    const params = Object.assign({}, this.state.params);
    params.unit = { id: valueUnit.value, name: valueUnit.label };
    this.setState({ params, valueUnit }, () => this.handleOnBlur());
  };

  handleChangeSelValueQualitative = valueQualitative => {
    const params = Object.assign({}, this.state.params);
    params.qualitative = [valueQualitative.label];
    this.setState({ params, valueQualitative }, () => this.handleOnBlur());
  };

  handleChangeValueInputDate = (label, date) => {
    const params = Object.assign({}, this.state.params);
    params[label] = date.toISOString();
    this.setState({ params }, () => this.handleOnBlur());
  };

  render() {
    const item = this.state.params;
    const actions = {
      updateOsmPlace: this.handleOsmPlace,
      updateInfo: this.handleAddOrUpdate,
      paramsData: {
        user: this.props.user,
        identifier: this.props.infoIdentifier,
        idParams: this.state.params.id
      }
    };
    return (
      <Paper style={{ marginTop: 25, marginBottom: 25 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell variant="head" style={{ width: "10%" }}>
                name
              </TableCell>
              <TableCell style={{ width: "90%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Select
                      value={this.state.valueName}
                      onChange={this.handleChangeSelValueName}
                      options={this.state.allParams}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head" style={{ width: "10%" }}>
                description
              </TableCell>
              <TableCell style={{ width: "90%" }}>
                <div>
                  <TextField
                    fullWidth
                    value={this.state.description}
                    onChange={this.handleChangeDesc}
                    onBlur={this.handleOnBlur}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows="2"
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">value</TableCell>
              <TableCell>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      type="number"
                      value={this.state.quantitative}
                      onChange={this.handleChangeQta}
                      onBlur={this.handleOnBlur}
                      margin="normal"
                      helperText="quantitative"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      value={this.state.valueUnit}
                      onChange={this.handleChangeSelValueUnit}
                      options={this.state.allUnits}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {item.system_parameter.qualitative !== null && (
                      <Select
                        value={this.state.valueQualitative}
                        onChange={this.handleChangeSelValueQualitative}
                        options={this.state.allQualitative}
                      />
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">date</TableCell>
              <TableCell>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <InputDatePicker
                      setdate={this.handleChangeValueInputDate}
                      currentDate={item.onset}
                      label="onset"
                    />
                    {item.onset === null && <div>not set, select a date</div>}
                  </Grid>
                  <Grid item xs={4}>
                    <InputDatePicker
                      setdate={this.handleChangeValueInputDate}
                      currentDate={item.expires}
                      label="expires"
                    />
                    {item.expires === null && <div>not set, select a date</div>}
                  </Grid>
                  <Grid item xs={4}>
                    <InputDatePicker
                      setdate={this.handleChangeValueInputDate}
                      currentDate={item.effective}
                      label="effective"
                    />
                    {item.effective === null && <div>not set, select a date</div>}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">areas</TableCell>
              <TableCell>
                <Grid container>
                  <Grid item xs={12}>
                    {item.areas !== null && <ParamAreas areas={item.areas} />}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <MapParamTable
                  id={`p-${this.props.index}`}
                  mapConfig={{ zoom: 3, height: 350 }}
                  areas={item.areas}
                  actions={actions}
                />
              </TableCell>
            </TableRow>
            {item.extra !== null && (
              <TableRow>
                <TableCell variant="head">extra</TableCell>
                <TableCell>
                  <ParamExtra extra={item.extra} />
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell />
              <TableCell>
                <div align="right">
                  {item.id > 0 ? (
                    <React.Fragment>
                      <Button
                        size="small"
                        variant="outlined"
                        style={{ color: red[500] }}
                        onClick={this.handleDeleteItem}
                      >
                        <DeleteIcon />
                        delete
                      </Button>
                    </React.Fragment>
                  ) : (
                    <div>todo</div>
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }

  handleCopyParams = _ => {
    this.props.copyParams(this.state.params);
  };

  handleChangeDesc = evt => {
    const params = Object.assign({}, this.state.params); // this.state.params;
    params.description = evt.target.value || `...`;
    this.setState({ description: evt.target.value, params });
  };

  handleChangeQta = evt => {
    const params = Object.assign({}, this.state.params); // this.state.params;
    params.quantitative = evt.target.value;
    this.setState({ quantitative: evt.target.value, params });
  };

  handleDeleteItem = _ => {
    this.props.delete(this.props.item);
  };

  handleOnBlur = _ => {
    this.handleAddOrUpdate();
  };

  handleAddOrUpdate = _ => {
    this.props.addOrUpdate(
      this.state.params,
      this.props.listType,
      this.props.indexRow
    );
  };
}

class EditSystemParameters extends React.Component {
  state = {
    addnew: "",
    systemParameters: [],
    newParams: [],
    oldParams: [],
    changedParams: []
  };

  UNSAFE_componentWillMount() {
    const parameters = [...this.props.info.parameters];
    this.setState({
      oldParams: parameters,
      systemParameters: this.props.allData.info[7]
    });
  }

  render() {
    const { classes } = this.props;
    const { systemParameters, oldParams } = this.state;
    return (
      <div style={{ marginTop: 30 }}>
        <Typography variant="h6">Parameters</Typography>
        {oldParams.map((p, idx) => (
          <EditValue
            key={`params-old-${p.id > 0 ? p.id : idx}`}
            item={p}
            update={this.handleUpdate}
            delete={this.handleDelete}
            addOrUpdate={this.handleAddUpdateParams}
            copyParams={this.copyParams}
            index={idx}
            indexRow={idx}
            listType="old"
            allData={this.props.allData}
            infoIdentifier={this.props.info.identifier}
            user={this.props.user}
          />
        ))}
        <div
          style={{
            marginTop: 10,
            marginLeft: 10,
            marginBottom: 10
          }}
        >
          <Typography variant="h6" gutterBottom>
            click on the parameter to add it
          </Typography>
        </div>
        <div className={classes.root}>
          <Paper style={{ padding: 10 }}>
            {systemParameters.map((i, idx) => (
              <Chip
                key={`all-${idx}`}
                className={classes.chip}
                label={i.value_name}
                onClick={() => this.handleChipClick(i)}
              />
            ))}
          </Paper>
        </div>
      </div>
    );
  }

  /*
  copyParams = param => {
    const newParam = Object.assign({}, param);
    newParam["id"] = 0;
    const newParams = [...this.state.newParams, newParam];
    this.setState({ newParams });
  };
  */

  handleAddUpdateParams = async (params, listType, indexRow) => {
    let errors = [];
    let unitID = UNIT_DEFAULT.value;
    if (params.hasOwnProperty("unit")) {
      if (params.unit !== null) {
        if (params.unit.hasOwnProperty("id")) {
          unitID = params.unit.id;
        }
      }
    }
    const qt = isNumeric(params.quantitative) ? params.quantitative : null;

    let ql = null;
    if (Array.isArray(params.qualitative)) {
      ql = params.qualitative;
    }
    const data = {
      system_parameter_id: params.system_parameter.id,
      description: params.description,
      quantitative: qt,
      unit_id: unitID,
      onset: params.onset
    };
    if (params.expires !== null) data["expires"] = params.expires;
    if (params.effective !== null) data["effective"] = params.effective;
    console.log(data);

    if (ql) data["qualitative"] = ql;

    if (params.id > 0) {
      const responsePatch = await this._patchParams(params.id, data);
      errors = checkResponse(responsePatch, errors, params);
    } else {
      const respPost = await this._postParams(data);
      errors = checkResponse(respPost, errors, params);
      if (errors.length === 0) {
        if (listType === "new") {
          let newParams = [...this.state.newParams];
          newParams[indexRow] = respPost;
          this.setState({ newParams });
        } else {
          let oldParams = [...this.state.oldParams];
          oldParams[indexRow] = respPost;
          this.setState({ oldParams });
        }
      }
    }
    if (errors.length === 0) {
      this.props.actions.reloadInfo(this.props.info.identifier);
    } else {
      console.log("errors...", errors);
      alert(`Errors, data not saved! ${JSON.stringify(errors)}`);
    }
  };

  handleDelete = async params => {
    const oldParams = this.state.oldParams.filter(f => f.id !== params.id);
    this.setState({ oldParams });
    const response = await this._deleteParams(params.id);
    if (response.status === 204) {
      this.props.actions.reloadInfo(this.props.info.identifier);
    } else {
      alert("error...");
    }
  };

  handleChipClick = async item => {
    const p = {
      id: 0,
      geometry: null,
      description: "",
      quantitative: "",
      qualitative: null,
      locations: null,
      system_parameter: null,
      unit: null,
      countries: [],
      regions: [],
      onset: null,
      expires: null,
      effective: null,
      extra: null,
      areas: null
    };
    p["system_parameter"] = item;
    const data = {
      system_parameter_id: p.system_parameter.id,
      description: "....",
      quantitative: null,
      /* qualitative: null, */
      unit_id: UNIT_DEFAULT.value
    };
    let errors = [];
    const respPost = await this._postParams(data);
    errors = checkResponse(respPost, errors, p);
    if (errors.length === 0) {
      const oldParams = [...this.state.oldParams, respPost];
      this.setState({ oldParams });
      this.props.actions.reloadInfo(this.props.info.identifier);
    }
  };

  _patchParams = async (id, data) => {
    const resParams = await patchInfoParameters(
      this.props.user,
      this.props.info.identifier,
      id,
      data
    );
    return resParams;
  };

  _postParams = async data => {
    const resParams = await postInfoParameters(
      this.props.user,
      this.props.info.identifier,
      data
    );
    return resParams;
  };

  _deleteParams = async id => {
    const resParams = await deleteInfoParameters(
      this.props.user,
      this.props.info.identifier,
      id
    );
    return resParams;
  };
}

EditSystemParameters.propTypes = {
  classes: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

export default withStyles(styles)(EditSystemParameters);
