import React from "react";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import TextField from "@material-ui/core/TextField";
import InfoIcon from "@material-ui/icons/Help";
import blue from "@material-ui/core/colors/blue";
import yellow from "@material-ui/core/colors/yellow";


const LIST_COLORS = {
  "1": "#93B459",
  "2": "#5e84b2",
  "3": "#B14D4A",
  "4": "#F5BA31",
  "5": "#EA863E",
  "6": "#7f6696",
  "8": "#D5D5CF"
};

const Level3 = ({ item, handleList, hazardList }) => {
  let i = hazardList.filter((l) => l === item.id);
  return (
    <ListItem button onClick={() => handleList(item)} style={{ marginLeft: 8 }}>
      {i.length === 1 ? <RemoveIcon /> : <AddIcon />}
      <Tooltip title={item.description} placement="bottom">
        <ListItemText primary={item.name} />
      </Tooltip>
    </ListItem>
  );
};

const HazardSubItem = ({ item, hazards, handleList, hazardList }) => {
  const [open, setOpen] = React.useState(false);
  let level3 = hazards.filter((h) => h.parent === item.id);
  let i = hazardList.filter((l) => l === item.id);
  return (
    <React.Fragment>
      <ListItem button style={{ marginLeft: 8 }}>
        {i.length === 1 ? (
          <RemoveIcon onClick={() => handleList(item)} />
        ) : (
            <AddIcon onClick={() => handleList(item)} />
          )}
        <Tooltip title={item.description} placement="bottom">
          <ListItemText primary={item.name} onClick={() => setOpen(!open)} />
        </Tooltip>
        {open ? (
          <ExpandLess
            onClick={() => setOpen(!open)}
            style={{ marginLeft: 8 }}
          />
        ) : (
            <ExpandMore
              onClick={() => setOpen(!open)}
              style={{ marginLeft: 8 }}
            />
          )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ marginLeft: 14 }}>
          {level3.map((l, idx) => (
            <Level3
              key={`h3-${idx}-${l.name}`}
              item={l}
              handleList={handleList}
              hazardList={hazardList}
            />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const HazardItem = ({ item, hazards, handleList, hazardList }) => {
  const [open, setOpen] = React.useState(false);
  let level2 = hazards.filter((h) => h.parent === item.id);
  let i = hazardList.filter((l) => l === item.id);
  return (
    <React.Fragment>
      <ListItem button style={{ background: LIST_COLORS[item.id] }}>
        {i.length === 1 ? (
          <RemoveIcon onClick={() => handleList(item)} />
        ) : (
            <AddIcon onClick={() => handleList(item)} />
          )}
        <Tooltip title={item.description} placement="top">
          <ListItemText primary={item.name} onClick={() => setOpen(!open)} />
        </Tooltip>
        {open ? (
          <ExpandLess onClick={() => setOpen(!open)} />
        ) : (
            <ExpandMore onClick={() => setOpen(!open)} />
          )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ background: LIST_COLORS[item.id] }}
        >
          {level2.map((l, idx) => (
            <HazardSubItem
              key={`h2-${idx}-${l.name}`}
              item={l}
              hazards={hazards}
              handleList={handleList}
              hazardList={hazardList}
            />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const HazardClassifications = ({ hazards, info, actions }) => {
  const [hazardList, updateList] = React.useState(info.hazard_classifications.map(c => c.id));
  const [open, setOpen] = React.useState(false);
  const level1 = hazards.filter((h) => h.parent < 1);
  const [search, setSearch] = React.useState("");

  return (
    <div style={{ display: "flex" }}>
      
      <div style={{ marginLeft: 10 }}>
        {open && (
          <React.Fragment>
            <div>
              <div>
                <TextField
                  id="search-data"
                  label="search"
                  onChange={handleChange}
                  value={search}
                />
              </div>
              <div>
                {search.length > 1 && (
                  <List style={{ width: 300 }}>
                    {hazards
                      .filter((h) => h.name.toLowerCase().includes(search))
                      .map((s, idx) => (
                        <ListItem
                          button
                          key={`search-${idx}`}
                          onClick={() => handleList(s)}
                        >
                          <ListItemText primary={s.name} />
                        </ListItem>
                      ))}
                  </List>
                )}
              </div>
            </div>
            {search.length < 1 && (
              <List style={{ width: 300 }}>
                {level1.map((h, idx) => (
                  <HazardItem
                    key={`h-${idx}`}
                    item={h}
                    hazards={hazards}
                    handleList={handleList}
                    hazardList={hazardList}
                  />
                ))}
              </List>
            )}
          </React.Fragment>
        )}
      </div>
      <div>
        <Tooltip title="Edit" placement="top">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            <EditIcon style={{ color: yellow[700] }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="help">
          <IconButton size="small" onClick={gotoHelpPage}>
            <InfoIcon style={{ color: blue[700] }} /></IconButton></Tooltip>
      </div>
      <div style={{ marginLeft: 10 }}>
        <List>
          {hazardList.map((k, idx) => (
            <ListItem button key={`added-${idx}`}>
              <RemoveIcon
                onClick={() => handleList(hazards.filter((h) => h.id === k)[0])}
              />
              <ListItemText
                primary={
                  hazards.filter((h) => h.id === k).map((h) => h.name)[0]
                }
              />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );

  function gotoHelpPage() {
    // http://www.irdrinternational.org/wp-content/uploads/2014/04/IRDR_DATA-Project-Report-No.-1.pdf
    // https://www.emdat.be/classification
    const url = 'https://www.emdat.be/classification'
    window.open(url, "_blank");
  }

  function handleChange(evt) {
    setSearch(evt.target.value);
  }

  function handleList(item) {
    const ll = new Set([...hazardList]);
    if (ll.has(item.id)) {
      ll.delete(item.id);
      updateList(Array.from(ll));
      actions.hazardsInfo(info,Array.from(ll))
      return;
    }
    ll.add(item.id);
    updateList(Array.from(ll));
    actions.hazardsInfo(info,Array.from(ll))
  }
};
export default HazardClassifications;
