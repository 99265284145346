import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class EditSimpleSelect extends React.Component {
  state = {
    valID: ""
  };

  UNSAFE_componentWillMount() {
    this.setState({ valID: this.props.current.id });
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.props.getIds(event.target.value, this.props.field);
  };

  render() {
    const { classes, label, field, values } = this.props;
    return (
      <FormControl fullWidth className={classes.formControl}>
        <InputLabel htmlFor="age-simple">{label}</InputLabel>
        <Select
          value={this.state.valID}
          onChange={this.handleChange}
          inputProps={{
            name: "valID",
            id: "age-simple"
          }}
        >
          {values.results.map((name, idx) => (
            <MenuItem key={`sel-${field}-${idx}`} value={name.id}>
              {name.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

EditSimpleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  getIds: PropTypes.func.isRequired
};

export default withStyles(styles)(EditSimpleSelect);
