import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    paper: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
  });

function Nodata(props) {
  const { classes } = props;
  return (
    <Paper className={classes.paper} elevation={1}>
      <Typography variant="h4" gutterBottom>
        no data
      </Typography>
    </Paper>
  );
}

export default withStyles(styles)(Nodata);
